import React, { useState, useEffect } from 'react'
import { WithContext as ReactTagInput } from 'react-tag-input'
import { Link } from 'react-router-dom'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'
import './FaqQuestions.css'

import {
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Pagination,
  Modal,
  Form,
} from 'react-bootstrap'

const MySwal = withReactContent(Swal)

const FaqQuestions = () => {
  const [questionsData, setQuestionsData] = useState([])
  const [categoriesData, setCategoriesData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [newQuestion, setNewQuestion] = useState('')
  const [newAnswer, setNewAnswer] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [editQuestionId, setEditQuestionId] = useState(null)
  const [tags, setTags] = useState([])

  // Define the fetchQuestions function
  const fetchQuestions = () => {
    setLoading(true)
    fetch(Constants.manageFAQ)
      .then((response) => response.json())
      .then((data) => {
        setQuestionsData(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching questions:', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    // Call fetchQuestions in useEffect
    fetchQuestions()

    // Fetch all categories
    fetch('https://api.icarbuyer.co/api/faqQuestions/categories')
      .then((response) => response.json())
      .then((data) => {
        setCategoriesData(data)
      })
      .catch((error) => {
        console.error('Error fetching categories:', error)
      })
  }, [])

  const handleAddQuestion = () => {
    setIsEditing(false)
    setNewQuestion('')
    setNewAnswer('')
    setSelectedCategory('')
    setTags([]);
    setShowModal(true)
  }

  const handleEditQuestion = (question) => {
    setIsEditing(true)
    setEditQuestionId(question._id)
    setNewQuestion(question.question)
    setNewAnswer(question.answer)
    setSelectedCategory(question.category._id)
    const formattedTags = question.tags.map((tag) => ({
      id: tag,
      text: tag,
    }));
    setTags(formattedTags); 
    setShowModal(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
    setNewQuestion('')
    setNewAnswer('')
    setSelectedCategory('')
    setTags([]); 
    setIsEditing(false)
    setEditQuestionId(null)
  }

  const handleModalSubmit = () => {
    if (!newQuestion.trim() || !newAnswer.trim() || !selectedCategory) {
      MySwal.fire('Error', 'All fields are required.', 'error')
      return
    }

    const formattedTags = tags.map(tag => tag.text);

    const requestBody = {
      question: newQuestion,
      answer: newAnswer,
      categoryId: selectedCategory,
      tags: formattedTags,
    }

    const url = isEditing ? `${Constants.manageFAQ}/${editQuestionId}` : Constants.manageFAQ;
    const method = isEditing ? 'PUT' : 'POST'

    fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    })
      .then(async (response) => {
        const data = await response.json()
        if (response.ok) {
          fetchQuestions() // Refetch the questions after adding or editing
          MySwal.fire(
            'Success',
            isEditing ? 'Question updated successfully.' : 'Question added successfully.',
            'success',
          )
          handleModalClose()
        } else {
          const errorMessage =
            data.message || (isEditing ? 'Failed to update question.' : 'Failed to add question.')
          throw new Error(errorMessage)
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        MySwal.fire('Error', error.message || 'An unexpected error occurred.', 'error')
      })
  }

  const handleDelete = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this question!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${Constants.manageFAQ}/${id}`, {
          method: 'DELETE',
        })
          .then((response) => {
            if (response.ok) {
              fetchQuestions() // Refetch the questions after deletion
              MySwal.fire('Deleted!', 'Question has been deleted.', 'success')
            } else {
              throw new Error('Failed to delete question.')
            }
          })
          .catch((error) => {
            console.error('Error deleting question:', error)
            MySwal.fire('Error', 'Failed to delete question.', 'error')
          })
      }
    })
  }

  const filteredQuestions = questionsData.filter((q) =>
    q.question.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredQuestions.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(filteredQuestions.length / itemsPerPage)

  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return
    setCurrentPage(pageNumber)
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">FAQ Questions</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">FAQ Questions</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <div className="card custom-shadow rounded-lg border">
                  <div className="card-body">
                    <Row className="d-flex justify-content-between">
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <h3 className="fw-bolder text-info mb-0">{filteredQuestions.length}</h3>
                        <p className="text-secondary">Total Questions</p>
                      </Col>
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <InputGroup className="mb-3">
                          <FormControl
                            placeholder="Search Questions..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <div>
                      <Button className="mb-3" variant="info" onClick={handleAddQuestion}>
                        Add New Question
                      </Button>

                      <Modal show={showModal} onHide={handleModalClose} size="lg">
                        <Modal.Header closeButton>
                          <Modal.Title>
                            {isEditing ? 'Edit Question' : 'Add New Question'}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group controlId="formCategory" className="mt-3">
                              <Form.Label>Category</Form.Label>
                              <Form.Control
                                as="select"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                              >
                                <option value="">Select Category</option>
                                {categoriesData.map((cat) => (
                                  <option key={cat._id} value={cat._id}>
                                    {cat.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formQuestion">
                              <Form.Label>Question</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter question"
                                value={newQuestion}
                                onChange={(e) => setNewQuestion(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group controlId="formAnswer" className="mt-3">
                              <Form.Label>Answer</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter answer"
                                value={newAnswer}
                                onChange={(e) => setNewAnswer(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group controlId="formTags" className="mt-3">
                              <Form.Label>Tags</Form.Label>
                              <ReactTagInput
                                tags={tags}
                                handleDelete={(i) =>
                                  setTags(tags.filter((tag, index) => index !== i))
                                }
                                handleAddition={(tag) => setTags([...tags, tag])}
                                placeholder="Add new tag"
                                className="ReactTags__tags"
                              />
                               <Form.Text className="text-mutedform-text bg-warning px-1 py-1">
    Add tags by typing and pressing "Enter" or ",". Click on a tag to remove it.
  </Form.Text>
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleModalClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={handleModalSubmit}>
                            {isEditing ? 'Update Question' : 'Save Question'}
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <Table responsive bordered hover>
                        <thead>
                          <tr>
                            <th width="200">Question</th>
                            <th>Answer</th>
                            <th width="130">Category</th>
                            <th width="120">Tag#</th>
                            <th width="90">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.map((q) => (
                            <tr key={q?._id}>
                              <td>{q?.question}</td>
                              <td>{q?.answer}</td>
                              <td>{q?.category?.name || 'N/A'}</td>
                              <td>{q?.tags?.join(', ') || 'N/A'}</td>
                              <td>
                                <Button
                                  variant="warning"
                                  className="me-2 btn-sm"
                                  onClick={() => handleEditQuestion(q)}
                                >
                                  <FaRegEdit />
                                </Button>
                                <Button
                                  variant="danger"
                                  onClick={() => handleDelete(q?._id)}
                                  className="btn-sm"
                                >
                                  <FaTrashAlt />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-center">
                        <Pagination>
                          <Pagination.First onClick={() => paginate(1)} />
                          <Pagination.Prev
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                          />
                          {[...Array(totalPages).keys()].map((index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage}
                              onClick={() => paginate(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          />
                          <Pagination.Last onClick={() => paginate(totalPages)} />
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  )
}

export default FaqQuestions
