import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  Form,
  Modal,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

// MultiSelect component with select-all functionality
const MultiSelect = (props) => {
  const [selectInput, setSelectInput] = useState('');
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef('Select all');
  const allOption = { value: '*', label: selectAllLabel.current };

  const filterOptions = (options, input) =>
    options?.filter(({ label }) => label.toLowerCase().includes(input.toLowerCase()));

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props) => (
    <components.Option {...props}>
      {props.value === '*' &&
      !isAllSelected.current &&
      filteredSelectedOptions?.length > 0 ? (
        <input
          key={props.value}
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          key={props.value}
          type="checkbox"
          checked={props.isSelected || isAllSelected.current}
          onChange={() => {}}
        />
      )}
      <label style={{ marginLeft: '5px' }}>{props.label}</label>
    </components.Option>
  );

  const customFilterOption = ({ value, label }, input) =>
    (value !== '*' && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === '*' && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === 'input-change') setSelectInput(inputValue);
    else if (event.action === 'menu-close' && selectInput !== '') setSelectInput('');
  };

  const handleChange = (selected) => {
    if (selected.some((option) => option.value === allOption.value)) {
      const newSelected = isAllSelected.current ? [] : [...props.options];
      isAllSelected.current = !isAllSelected.current;
      props.onChange(newSelected);
    } else {
      props.onChange(selected);
    }
  };

  const customStyles = {
    multiValueLabel: (base) => ({
      ...base,
      backgroundColor: 'lightgray',
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: 'lightgray',
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: '65px',
      overflow: 'auto',
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected ? '#DEEBFF' : isFocused ? '#F5F5F5' : null,
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <Select
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      options={[allOption, ...props.options]}
      onChange={handleChange}
      components={{ Option }}
      filterOption={customFilterOption}
      menuPlacement={props.menuPlacement ?? 'auto'}
      styles={customStyles}
      isMulti
      closeMenuOnSelect={false}
      tabSelectsValue={false}
      hideSelectedOptions={false}
      blurInputOnSelect={false}
    />
  );
};

const AddDealerCar = () => {
  const { id } = useParams(); // dealer id comes from params
  const navigate = useNavigate();
  const [dealerDetails, setDealerDetails] = useState([
    { make: '', vehicleType: '', carModels: [] },
  ]);
  const [makesData, setMakesData] = useState({});
  const [carData, setCarData] = useState({});
  const [dealerCarMakes, setDealerCarMakes] = useState([]);

  // --- State for Edit Modal ---
  const [showEditModal, setShowEditModal] = useState(false);
  const [editRecordId, setEditRecordId] = useState('');
  const [editImages, setEditImages] = useState({
    backgroundImg: null,
    bannerImg: null,
    iconImg: null,
    existingBackgroundImg: '',
    existingBannerImg: '',
    existingIconImg: '',
  });
  const [vehicleTypeHeading, setVehicleTypeHeading] = useState('');
  // -----------------------------

  // Fetch dealer makes and vehicle types
  useEffect(() => {
    fetch(`https://api.icarbuyer.co/api/dealer/dealerMakes/${id}`)
      .then((response) => response.json())
      .then((data) => {
        const fetchedData = {};
        data.data.forEach((item) => {
          fetchedData[item.make] = item.vehicleTypes;
        });
        setMakesData(fetchedData);
      })
      .catch((error) => {
        MySwal.fire('Error', 'Failed to fetch makes and vehicle types', 'error');
        console.error('Error fetching makes and vehicle types:', error);
      });
  }, [id]);

  // Fetch existing dealer car listings
  useEffect(() => {
    if (id) {
      fetch(`https://api.icarbuyer.co/api/dealer/dealersMakeAndModels/${id}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 1) {
            setDealerCarMakes(data.data.carDetails);
          } else {
            MySwal.fire('Error', data.message || 'Failed to fetch car details', 'error');
          }
        })
        .catch((error) => {
          MySwal.fire('Error', 'Failed to fetch car details', 'error');
          console.error('Error fetching car details:', error);
        });
    }
  }, [id]);

  // --- Form Handlers for Adding Dealer Car Details ---
  const handleMakeChange = (selectedMake, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].make = selectedMake.value;
    newDealerDetails[index].vehicleType = '';
    newDealerDetails[index].carModels = [];
    setDealerDetails(newDealerDetails);
  };

  const handleVehicleTypeChange = (selectedVehicleType, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].vehicleType = selectedVehicleType.value;
    newDealerDetails[index].carModels = [];
    setDealerDetails(newDealerDetails);

    const { make } = newDealerDetails[index];
    if (make && selectedVehicleType.value) {
      fetch(
        `https://api.icarbuyer.co/api/car/getModelNamesByMakeAndType/${make}/${selectedVehicleType.value}`
      )
        .then((response) => response.json())
        .then((data) => {
          setCarData((prevState) => ({
            ...prevState,
            [`${make}-${selectedVehicleType.value}`]: data,
          }));
        })
        .catch((error) => {
          MySwal.fire('Error', 'Failed to fetch car models', 'error');
          console.error('Error fetching car models:', error);
        });
    }
  };

  const handleCarModelChange = (selectedCarModels, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].carModels = selectedCarModels.map((carModel) => carModel.value);
    setDealerDetails(newDealerDetails);
  };

  const addMoreDealerDetails = () => {
    setDealerDetails([...dealerDetails, { make: '', vehicleType: '', carModels: [] }]);
  };

  const removeDealerDetails = (index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails.splice(index, 1);
    setDealerDetails(newDealerDetails.length ? newDealerDetails : [{ make: '', vehicleType: '', carModels: [] }]);
  };

  const getAvailableMakes = () => {
    const selectedMakesWithAllTypesSelected = dealerDetails
      .filter((detail) => detail.make)
      .reduce((acc, detail) => {
        const make = detail.make;
        const typesForMake = dealerDetails.filter((d) => d.make === make).map((d) => d.vehicleType);
        if (makesData[make] && typesForMake.length === makesData[make].length) {
          acc.push(make);
        }
        return acc;
      }, []);

    return Object.keys(makesData)
      .filter((make) => !selectedMakesWithAllTypesSelected.includes(make))
      .map((make) => ({
        value: make,
        label: make,
      }));
  };

  const getAvailableVehicleTypes = (make) => {
    const selectedVehicleTypes = dealerDetails
      .filter((detail) => detail.make === make)
      .map((detail) => detail.vehicleType)
      .filter(Boolean);

    return makesData[make]
      ? makesData[make]
          .filter((type) => !selectedVehicleTypes.includes(type))
          .map((type) => ({
            value: type,
            label: type,
          }))
      : [];
  };

  const saveDetails = () => {
    const payload = {
      carDetails: dealerDetails,
    };

    fetch(`https://api.icarbuyer.co/api/dealer/addCarDetails/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Car details added successfully') {
          MySwal.fire('Success', data.message, 'success').then(() => {
            // Refresh the dealer car listings
            fetch(`https://api.icarbuyer.co/api/dealer/dealersMakeAndModels/${id}`)
              .then((res) => res.json())
              .then((data) => {
                if (data.status === 1) {
                  setDealerCarMakes(data.data.carDetails);
                }
              });
          });
        } else {
          MySwal.fire('Error', data.message || 'Failed to save details', 'error');
        }
      })
      .catch((error) => {
        MySwal.fire('Error', 'Failed to save details', 'error');
        console.error('Error saving details:', error);
      });
  };
  // -------------------------------------------------------

  // --- Dealer Car Listings Table ---
  const groupMakesAndModels = () => {
    const groupedMakes = dealerCarMakes.reduce((acc, item) => {
      const { make, vehicleType, carModels, backgroundImg, bannerImg, iconImg, _id, vehicleTypeHeading } = item;
      if (!acc[make]) {
        acc[make] = {};
      }
      acc[make][vehicleType] = {
        carModels,
        backgroundImg,
        bannerImg,
        iconImg,
        id: _id,
        vehicleTypeHeading,
      };
      return acc;
    }, {});
    return groupedMakes;
  };

  const renderMakesAndModelsTable = () => {
    const groupedMakes = groupMakesAndModels();
    return (
      <table className="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th>Make</th>
            <th>Vehicle Type</th>
            <th>Car Models</th>
            <th>Heading</th>
            <th>Background Image</th>
            <th>Banner Image</th>
            <th>Logo Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedMakes).map((make) =>
            Object.keys(groupedMakes[make]).map((vehicleType, idx) => {
              const { carModels, backgroundImg, bannerImg, iconImg, id, vehicleTypeHeading } =
                groupedMakes[make][vehicleType];
              return (
                <tr key={`${make}-${vehicleType}`}>
                  <td>{idx === 0 ? make : ''}</td>
                  <td>{vehicleType}</td>
                  <td>{carModels.join(', ')}</td>
                  <td>{vehicleTypeHeading || 'Admin Heading'}</td>
                  <td>
                    <img
                      src={
                        backgroundImg
                          ? `https://api.icarbuyer.co/static/${backgroundImg}`
                          : 'https://api.icarbuyer.co/static/adminimg.jpg'
                      }
                      alt="Background"
                      style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                  </td>
                  <td>
                    <img
                      src={
                        bannerImg
                          ? `https://api.icarbuyer.co/static/${bannerImg}`
                          : 'https://api.icarbuyer.co/static/adminimg.jpg'
                      }
                      alt="Banner"
                      style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                  </td>
                  <td>
                    <img
                      src={
                        iconImg
                          ? `https://api.icarbuyer.co/static/${iconImg}`
                          : 'https://api.icarbuyer.co/static/adminimg.jpg'
                      }
                      alt="Icon"
                      style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() =>
                        handleEditClick(
                          id,
                          backgroundImg,
                          bannerImg,
                          iconImg,
                          vehicleTypeHeading
                        )
                      }
                    >
                      Edit
                    </Button>{' '}
                    <Button variant="danger" onClick={() => handleDelete(id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    );
  };
  // -------------------------------------------------------

  // --- Edit Modal Handlers ---
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditRecordId('');
    setEditImages({
      backgroundImg: null,
      bannerImg: null,
      iconImg: null,
      existingBackgroundImg: '',
      existingBannerImg: '',
      existingIconImg: '',
    });
    setVehicleTypeHeading('');
  };

  const handleFileChange = (field, file) => {
    setEditImages((prev) => ({
      ...prev,
      [field]: file,
    }));
  };

  const handleEditClick = (
    id,
    backgroundImg,
    bannerImg,
    iconImg,
    existingVehicleTypeHeading
  ) => {
    setEditRecordId(id);
    setEditImages({
      backgroundImg: null,
      bannerImg: null,
      iconImg: null,
      existingBackgroundImg: backgroundImg || '',
      existingBannerImg: bannerImg || '',
      existingIconImg: iconImg || '',
    });
    setVehicleTypeHeading(existingVehicleTypeHeading || '');
    setShowEditModal(true);
  };

  const handleEditSave = () => {
    if (!editRecordId) {
      MySwal.fire('Error', 'No record selected for editing', 'error');
      return;
    }
    const formData = new FormData();
    if (editImages.backgroundImg) {
      formData.append('backgroundImg', editImages.backgroundImg);
    }
    if (editImages.bannerImg) {
      formData.append('bannerImg', editImages.bannerImg);
    }
    if (editImages.iconImg) {
      formData.append('iconImg', editImages.iconImg);
    }
    // Append vehicleTypeHeading as text
    formData.append('vehicleTypeHeading', vehicleTypeHeading);
    fetch(`https://api.icarbuyer.co/api/dealer/editCarDetails/${editRecordId}`, {
      method: 'PUT',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 1) {
          MySwal.fire('Success', data.message, 'success').then(() => {
            fetch(`https://api.icarbuyer.co/api/dealer/dealersMakeAndModels/${id}`)
              .then((res) => res.json())
              .then((newData) => {
                if (newData.status === 1) {
                  setDealerCarMakes(newData.data.carDetails);
                }
              });
          });
          handleCloseEditModal();
        } else {
          MySwal.fire('Error', data.message || 'Failed to update images', 'error');
        }
      })
      .catch((error) => {
        MySwal.fire('Error', 'Failed to update images', 'error');
        console.error('Error editing details:', error);
      });
  };

  const handleDelete = (recordId) => {
    if (!recordId) return;
    MySwal.fire({
      title: 'Delete Confirmation',
      text: 'Are you sure you want to delete this car detail?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://api.icarbuyer.co/api/dealer/deleteCarDetails/${recordId}`, {
          method: 'DELETE',
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === 1) {
              MySwal.fire('Deleted!', data.message, 'success');
              fetch(`https://api.icarbuyer.co/api/dealer/dealersMakeAndModels/${id}`)
                .then((res) => res.json())
                .then((newData) => {
                  if (newData.status === 1) {
                    setDealerCarMakes(newData.data.carDetails);
                  }
                })
                .catch((err) => {
                  console.error('Error refreshing car details:', err);
                });
            } else {
              MySwal.fire('Error', data.message || 'Failed to delete car detail', 'error');
            }
          })
          .catch((err) => {
            MySwal.fire('Error', 'Failed to delete car detail', 'error');
            console.error('Delete error:', err);
          });
      }
    });
  };
  // -------------------------------------------------------

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <Col className="col-md-6">
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Add Dealer Car</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <Card.Header className="border-bottom">
              <h6 className="m-0">Add Dealer Car</h6>
            </Card.Header>
            <Card.Body className="p-0 pb-3">
              <Form>
                {dealerDetails.map((dealerDetail, index) => (
                  <Row form className="p-3" key={index}>
                    <Col md="3" className="form-group">
                      <label htmlFor={`make-${index}`}>Make</label>
                      <Select
                        id={`make-${index}`}
                        value={
                          dealerDetail.make
                            ? { value: dealerDetail.make, label: dealerDetail.make }
                            : null
                        }
                        onChange={(selectedMake) => handleMakeChange(selectedMake, index)}
                        options={getAvailableMakes()}
                        placeholder="Select Make"
                      />
                    </Col>
                    <Col md="3" className="form-group">
                      <label htmlFor={`vehicleType-${index}`}>Vehicle Type</label>
                      <Select
                        id={`vehicleType-${index}`}
                        value={
                          dealerDetail.vehicleType
                            ? { value: dealerDetail.vehicleType, label: dealerDetail.vehicleType }
                            : null
                        }
                        onChange={(selectedVehicleType) =>
                          handleVehicleTypeChange(selectedVehicleType, index)
                        }
                        options={dealerDetail.make ? getAvailableVehicleTypes(dealerDetail.make) : []}
                        placeholder="Select Vehicle Type"
                      />
                    </Col>
                    <Col md="3" className="form-group">
                      <label htmlFor={`carModels-${index}`}>Car Models</label>
                      <MultiSelect
                        id={`carModels-${index}`}
                        value={
                          dealerDetail.carModels.map((model) => ({
                            value: model,
                            label: model,
                          })) || []
                        }
                        onChange={(selectedCarModels) => handleCarModelChange(selectedCarModels, index)}
                        options={
                          dealerDetail.make &&
                          dealerDetail.vehicleType &&
                          carData[`${dealerDetail.make}-${dealerDetail.vehicleType}`]
                            ? carData[`${dealerDetail.make}-${dealerDetail.vehicleType}`].map((model) => ({
                                value: model,
                                label: model,
                              }))
                            : []
                        }
                        placeholder="Select Car Models"
                      />
                    </Col>
                    <Col md="3" className="form-group">
                      <label>&nbsp;</label>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button variant="danger" onClick={() => removeDealerDetails(index)}>
                          Remove
                        </Button>
                        {index === dealerDetails.length - 1 && (
                          <Button variant="primary" className="ml-3" onClick={addMoreDealerDetails}>
                            Add More
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                ))}
                <Button variant="primary" onClick={saveDetails} className="mx-3">
                  Save Details
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Dealer Car Listings */}
      <Row>
        <Col>
          <Card small className="mb-4">
            <Card.Header className="border-bottom">
              <h6 className="m-0">Dealer Car Listings</h6>
            </Card.Header>
            <Card.Body className="p-0 pb-3">{renderMakesAndModelsTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Car Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Vehicle Type Heading</Form.Label>
              <Form.Control
                type="text"
                value={vehicleTypeHeading}
                onChange={(e) => setVehicleTypeHeading(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Background Image [720*512 pixels]</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) =>
                  handleFileChange('backgroundImg', e.target.files ? e.target.files[0] : null)
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Banner Image [2000*848 pixels]</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) =>
                  handleFileChange('bannerImg', e.target.files ? e.target.files[0] : null)
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Logo Image [51*53 pixels]</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) =>
                  handleFileChange('iconImg', e.target.files ? e.target.files[0] : null)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AddDealerCar;
