import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  Form,
  Table,
  Modal
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

// ------------------- MultiSelect Component -------------------
const MultiSelect = (props) => {
  const [selectInput, setSelectInput] = useState("");
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };

  const filterOptions = (options, input) =>
    options?.filter(({ label }) =>
      label.toLowerCase().includes(input.toLowerCase())
    );

  const comparator = (v1, v2) => v1.value.localeCompare(v2.value);

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (optionProps) => (
    <components.Option {...optionProps}>
      {optionProps.value === "*" &&
      !isAllSelected.current &&
      filteredSelectedOptions?.length > 0 ? (
        <input
          key={optionProps.value}
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          key={optionProps.value}
          type="checkbox"
          checked={optionProps.isSelected || isAllSelected.current}
          onChange={() => {}}
        />
      )}
      <label style={{ marginLeft: "5px" }}>{optionProps.label}</label>
    </components.Option>
  );

  const Input = (inputProps) => (
    <>
      {selectInput.length === 0 ? (
        <components.Input autoFocus={inputProps.selectProps.menuIsOpen} {...inputProps}>
          {inputProps.children}
        </components.Input>
      ) : (
        <div style={{ border: "1px dotted gray" }}>
          <components.Input autoFocus={inputProps.selectProps.menuIsOpen} {...inputProps}>
            {inputProps.children}
          </components.Input>
        </div>
      )}
    </>
  );

  const customFilterOption = ({ value, label }, input) =>
    (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
          JSON.stringify(selected.sort(comparator)))
    )
      return props.onChange(
        [
          ...(props.value ?? []),
          ...props.options.filter(
            ({ label }) =>
              label.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (props.value ?? []).filter((opt) => opt.label === label).length === 0
          ),
        ].sort(comparator)
      );
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
        JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);
    else
      return props.onChange([
        ...props.value?.filter(
          ({ label }) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
  };

  const customStyles = {
    multiValueLabel: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    multiValueRemove: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: "65px",
      overflow: "auto",
    }),
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
            ? styles.backgroundColor
            : isFocused && isSelected
            ? "#DEEBFF"
            : null,
        color: isSelected ? null : null,
      };
    },
    menu: (def) => ({ ...def, zIndex: 9999 }),
  };

  if (props.isSelectAll && props.options.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) ===
      JSON.stringify(filteredOptions);

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `All (${filteredOptions.length}) selected`;
      else
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
    } else selectAllLabel.current = "Select all";

    allOption.label = selectAllLabel.current;

    return (
      <Select
        {...props}
        inputValue={selectInput}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        options={[allOption, ...props.options]}
        onChange={handleChange}
        components={{
          Option: Option,
          Input: Input,
          ...props.components,
        }}
        filterOption={customFilterOption}
        menuPlacement={props.menuPlacement ?? "auto"}
        styles={customStyles}
        isMulti
        closeMenuOnSelect={false}
        tabSelectsValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
      />
    );
  }

  return (
    <Select
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      filterOption={customFilterOption}
      components={{
        Input: Input,
        ...props.components,
      }}
      menuPlacement={props.menuPlacement ?? "auto"}
      onKeyDown={onKeyDown}
      tabSelectsValue={false}
      hideSelectedOptions={true}
      backspaceRemovesValue={false}
      blurInputOnSelect={true}
    />
  );
};

// ------------------- EditMakeModal Component -------------------
const EditMakeModal = ({ show, handleClose, make, handleUpdate }) => {
  const [bannerImage, setBannerImage] = useState(null);
  const [image, setImage] = useState(null);
  const [brandIcon, setBrandIcon] = useState(null);
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (make) {
      setHeading(make.heading || "");
      setDescription(make.description || "");
    }
  }, [make]);

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    if (bannerImage) formData.append('bannerImage', bannerImage);
    if (image) formData.append('image', image);
    if (brandIcon) formData.append('brandIcon', brandIcon);
    formData.append('heading', heading);
    formData.append('description', description);

    fetch(`https://api.icarbuyer.co/api/dealer/edit-make/${make._id}`, {
      method: 'PUT',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          MySwal.fire({
            icon: 'success',
            title: 'Success',
            text: data.message
          });
          handleUpdate(make._id, data.updatedMake);
          handleClose();
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to update make. Please try again.'
          });
        }
      })
      .catch(error => {
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update make. Please try again.'
        });
        console.error('Error:', error);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Make</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formHeading">
            <Form.Label>Heading</Form.Label>
            <Form.Control
              type="text"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formBannerImage">
            <Form.Label>Banner Image [2000*848 pixels]</Form.Label>
            <Form.Control type="file" onChange={(e) => handleFileChange(e, setBannerImage)} />
          </Form.Group>
          <Form.Group controlId="formImage">
            <Form.Label>Background Image [720*512 pixels]</Form.Label>
            <Form.Control type="file" onChange={(e) => handleFileChange(e, setImage)} />
          </Form.Group>
          <Form.Group controlId="formBrandIcon">
            <Form.Label>Brand Icon  [51*53 pixels]</Form.Label>
            <Form.Control type="file" onChange={(e) => handleFileChange(e, setBrandIcon)} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// ------------------- DealerAction Component -------------------
const DealerAction = () => {
  const { id } = useParams();
  const [dealerID, setDealerID] = useState(id);
  const [dealerDetails, setDealerDetails] = useState([{ make: '', vehicleTypes: [] }]);
  const [carData, setCarData] = useState({});
  const [existingMakes, setExistingMakes] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedMake, setSelectedMake] = useState(null);

  // Fetch car data on mount
  useEffect(() => {
    fetch('https://api.icarbuyer.co/api/make/make-summary')
      .then(response => response.json())
      .then(data => {
        const transformedCarData = {};
        data.forEach(make => {
          const makeName = make?.name?.toUpperCase();
          const vehicleTypes = make?.vehicleTypesDeails?.map(detail => detail?.vehicleTypeName?.name.toUpperCase());
          transformedCarData[makeName] = vehicleTypes;
        });
        setCarData(transformedCarData);
      })
      .catch(error => {
        console.error('Error fetching car data:', error);
      });
  }, []);

  // Fetch existing makes for this dealer
  useEffect(() => {
    if (!dealerID) return;
    fetch(`https://api.icarbuyer.co/api/dealer/dealersMakeAndModels/${dealerID}`)
      .then(response => response.json())
      .then(data => {
        if (data?.data?.makes) {
          setExistingMakes(data.data.makes);
        }
      })
      .catch(error => {
        console.error('Error fetching existing makes:', error);
      });
  }, [dealerID]);

  const handleMakeChange = (selectedMake, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].make = selectedMake.value;
    newDealerDetails[index].vehicleTypes = [];
    setDealerDetails(newDealerDetails);
  };

  const handleVehicleTypeChange = (selectedVehicleTypes, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].vehicleTypes = selectedVehicleTypes.map(vehicleType => vehicleType.value);
    setDealerDetails(newDealerDetails);
  };

  const addMoreDealerDetails = () => {
    setDealerDetails([...dealerDetails, { make: '', vehicleTypes: [] }]);
  };

  const removeDealerDetails = (index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails.splice(index, 1);
    setDealerDetails(newDealerDetails.length ? newDealerDetails : [{ make: '', vehicleTypes: [] }]);
  };

  const saveDetails = () => {
    fetch(`https://api.icarbuyer.co/api/dealer/dealers/addMakes/${dealerID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ makes: dealerDetails })
    })
    .then(response => response.json())
    .then(data => {
      MySwal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Dealer details have been saved successfully!'
      });
      console.log('Success:', data);
      // Refresh existing makes list
      fetch(`https://api.icarbuyer.co/api/dealer/dealersMakeAndModels/${dealerID}`)
        .then(res => res.json())
        .then(refetchData => {
          if (refetchData?.data?.makes) {
            setExistingMakes(refetchData.data.makes);
          }
        })
        .catch(err => console.error(err));
    })
    .catch(error => {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to save dealer details. Please try again.'
      });
      console.error('Error:', error);
    });
  };

  // Edit and Delete functionality for existing makes
  const handleEdit = (make) => {
    setSelectedMake(make);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedMake(null);
  };

  const handleUpdate = (makeId, updatedMake) => {
    setExistingMakes(existingMakes.map(make => make._id === makeId ? updatedMake : make));
  };

  const handleDelete = (makeId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "This action will permanently delete this make.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://api.icarbuyer.co/api/dealer/delete-make/${makeId}`, {
          method: "DELETE",
        })
          .then(response => response.json())
          .then(data => {
            if (data.status === 1) {
              MySwal.fire({
                icon: "success",
                title: "Deleted!",
                text: data.message,
              });
              setExistingMakes(existingMakes.filter(make => make._id !== makeId));
            } else {
              MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to delete make. Please try again.",
              });
            }
          })
          .catch(error => {
            MySwal.fire({
              icon: "error",
              title: "Error",
              text: "Failed to delete make. Please try again.",
            });
            console.error("Error:", error);
          });
      }
    });
  };

  const selectedMakes = dealerDetails.map(detail => detail.make);
  const availableMakeOptions = Object.keys(carData).map(make => ({
    value: make,
    label: make
  })).filter(make => !selectedMakes.includes(make.value));

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          {/* Page Title & Breadcrumb */}
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="text-info m-1">ADD DEALER DETAILS</h4>
                <div className="page-title-right">
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active className="text-info">Dealer Actions</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>

          {/* Add Dealer Details Form */}
          <Row>
            <Col xl={12}>
              <Card className="custom-shadow rounded-lg border my-2">
                <Card.Body>
                  {dealerDetails.map((detail, index) => (
                    <div key={index} className="mb-3">
                      <Row className="align-items-center">
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Make</Form.Label>
                            <Select
                              value={detail.make ? { value: detail.make, label: detail.make } : null}
                              onChange={(selectedMake) => handleMakeChange(selectedMake, index)}
                              options={availableMakeOptions}
                              classNamePrefix="select"
                              placeholder="Select Make"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Vehicle Types</Form.Label>
                            <MultiSelect
                              value={detail.vehicleTypes.map(type => ({ value: type, label: type }))}
                              onChange={(selectedVehicleTypes) => handleVehicleTypeChange(selectedVehicleTypes, index)}
                              options={
                                detail.make
                                  ? carData[detail.make].map(vehicleType => ({
                                      value: vehicleType,
                                      label: vehicleType
                                    }))
                                  : []
                              }
                              isMulti
                              classNamePrefix="select"
                              placeholder="Select Vehicle Types"
                              isSelectAll={true}
                              menuPlacement={"bottom"}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4} className="d-flex align-items-end">
                          <div className="d-flex justify-content-between w-100 mt-4">
                            {dealerDetails.length > 1 && (
                              <Button variant="danger" onClick={() => removeDealerDetails(index)} className="mr-2">
                                Remove
                              </Button>
                            )}
                            {index === dealerDetails.length - 1 && (
                              <Button variant="primary" onClick={addMoreDealerDetails}>
                                Add More
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-3">
                    <Button variant="success" onClick={saveDetails} className="mx-2">
                      Save
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Listing of Current Makes & Vehicle Types */}
          <Row className="mt-4">
            <Col xl={12}>
              <Card className="custom-shadow rounded-lg border my-2">
                <Card.Body>
                  <h4>Current Makes & Vehicle Types</h4>
                  <Table striped bordered hover responsive className="mt-3">
                    <thead>
                      <tr>
                        <th>Make</th>
                        <th>Vehicle Types</th>
                        <th>Heading</th>
                        <th>Description</th>
                        <th>Banner Image</th>
                        <th>Background Image</th>
                        <th>Brand Icon</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {existingMakes?.length > 0 ? (
                        existingMakes.map((item) => (
                          <tr key={item._id}>
                            <td>{item.make}</td>
                            <td>{item.vehicleTypes.join(", ")}</td>
                            <td>{item.heading || "Admin Heading"}</td>
                            <td>{item.description || "Admin Description"}</td>
                            <td>
                              {item.bannerImage ? (
                                <img
                                  src={`https://api.icarbuyer.co/static/${item.bannerImage}`}
                                  alt={`${item.make} banner`}
                                  style={{ width: '100px', height: 'auto' }}
                                />
                              ) : (
                                <img
                                  src="https://api.icarbuyer.co/static/adminimg.jpg"
                                  alt="Default Banner"
                                  style={{ width: '40px', height: 'auto' }}
                                />
                              )}
                            </td>
                            <td>
                              {item.image ? (
                                <img
                                  src={`https://api.icarbuyer.co/static/${item.image}`}
                                  alt={`${item.make} background`}
                                  style={{ width: '50px', height: 'auto' }}
                                />
                              ) : (
                                <img
                                  src="https://api.icarbuyer.co/static/adminimg.jpg"
                                  alt="Default Background"
                                  style={{ width: '40px', height: 'auto' }}
                                />
                              )}
                            </td>
                            <td>
                              {item.brandIcon ? (
                                <img
                                  src={`https://api.icarbuyer.co/static/${item.brandIcon}`}
                                  alt={`${item.make} icon`}
                                  style={{ width: '40px', height: 'auto' }}
                                />
                              ) : (
                                <img
                                  src="https://api.icarbuyer.co/static/adminimg.jpg"
                                  alt="Default Icon"
                                  style={{ width: '40px', height: 'auto' }}
                                />
                              )}
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                onClick={() => handleEdit(item)}
                                style={{ marginRight: '5px' }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => handleDelete(item._id)}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">No existing makes found.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {selectedMake && (
        <EditMakeModal
          show={showEditModal}
          handleClose={handleCloseEditModal}
          make={selectedMake}
          handleUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

export default DealerAction;
