import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaEye, FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Pagination,
  Dropdown,
} from 'react-bootstrap'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const ProjectList = () => {
  const [makesData, setMakesData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(Constants.getAllMakes)
      .then((response) => response.json())
      .then((data) => {
        setMakesData(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching makes:', error)
        setLoading(false)
      })
  }, [])

  const decodeHtmlEntities = (str) => {
    const textArea = document.createElement('textarea')
    textArea.innerHTML = str
    return textArea.value
  }

  const stripHtmlTags = (str) => {
    if (!str) return ''
    return str.replace(/<\/?[^>]+(>|$)/g, '') // Removes HTML tags
  }

  const escapeCsvValue = (value) => {
    if (!value) return ''
    const escapedValue = value.replace(/"/g, '""') // Escape double quotes
    return `"${escapedValue}"` // Enclose in double quotes
  }

  const exportToCSV = () => {
    const headers = [
      'Id',
      'Make',
      'Primary Color',
      'Secondary Color',
      'Heading',
      'Description',
      'Status',
    ]
    const csvRows = [
      headers.join(','),
      ...makesData.map((make) =>
        [
          escapeCsvValue(make._id),
          escapeCsvValue(make.name),
          escapeCsvValue(make.primaryColor || ''),
          escapeCsvValue(make.secondaryColor || ''),
          escapeCsvValue(make.heading),
          escapeCsvValue(decodeHtmlEntities(stripHtmlTags(make.description))),
          escapeCsvValue(make.status),
        ].join(','),
      ),
    ]

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'makes_list.csv'
    link.click()
    window.URL.revokeObjectURL(url)
  }

  const handleStatusToggle = (id, currentStatus) => {
    fetch(`${Constants.updateMakeStatus + id}/status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: currentStatus === 'active' ? 'inactive' : 'active' }),
    })
      .then((response) => {
        if (response.ok) {
          setMakesData((prevMakesData) =>
            prevMakesData.map((make) =>
              make._id === id
                ? { ...make, status: currentStatus === 'active' ? 'inactive' : 'active' }
                : make,
            ),
          )
          MySwal.fire('Updated!', 'Make status has been updated.', 'success')
        } else {
          throw new Error('Failed to update make status.')
        }
      })
      .catch((error) => {
        console.error('Error updating make status:', error)
        MySwal.fire('Error', 'Failed to update make status.', 'error')
      })
  }

  const handleDelete = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this make!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(Constants.deleteMakeByID + id, {
          method: 'DELETE',
        })
          .then((response) => {
            if (response.ok) {
              setMakesData((prevMakesData) => prevMakesData.filter((make) => make._id !== id))
              MySwal.fire('Deleted!', 'Make has been deleted.', 'success')
            } else {
              throw new Error('Failed to delete make.')
            }
          })
          .catch((error) => {
            console.error('Error deleting make:', error)
            MySwal.fire('Error', 'Failed to delete make.', 'error')
          })
      }
    })
  }

  const handleItemsPerPageChange = (eventKey) => {
    setItemsPerPage(parseInt(eventKey, 10))
    setCurrentPage(1) // Reset to first page after changing items per page
  }

  const filteredMakes = makesData.filter((make) =>
    make.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredMakes.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(filteredMakes.length / itemsPerPage)

  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return
    setCurrentPage(pageNumber)
  }

  const editMakeLink = (id) => `/editMakeDetails/${id}`

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">MAKE / BRAND LIST</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Makes List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <div className="card custom-shadow rounded-lg border">
                  <div className="card-body">
                    <Row className="d-flex justify-content-between align-items-center">
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <h3 className="fw-bolder text-info mb-0"> {filteredMakes.length} </h3>
                        <p className="text-secondary">Total Makes</p>
                      </Col>
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <Button className="mb-3" variant="info" as={Link} to="/addNewProduct">
                          Add New Make
                        </Button>
                      </Col>
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <Button variant="success" onClick={exportToCSV}>
                          Export to CSV
                        </Button>
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="search-box">
                        <input
                          type="text"
                          id="search"
                          placeholder="Search makes..."
                          name="search"
                          className="form-control"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        <span className="mx-1">Show </span>
                        <Dropdown onSelect={handleItemsPerPageChange} className="mx-1">
                          <Dropdown.Toggle variant="info" id="dropdown-basic">
                            {itemsPerPage}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey={10}>10</Dropdown.Item>
                            <Dropdown.Item eventKey={25}>25</Dropdown.Item>
                            <Dropdown.Item eventKey={50}>50</Dropdown.Item>
                            <Dropdown.Item eventKey={100}>100</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <span> items per page</span>
                      </div>
                    </div>
                    <div>
                      <Table responsive bordered hover>
                        <thead>
                          <tr>
                            <th>Makes</th>
                            <th>Primary Color</th>
                            <th>Secondary Color</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((make) => (
                            <React.Fragment key={make?._id}>
                              <tr>
                                <td>{make?.name}</td>
                                <td>
                                  <div
                                    style={{
                                      width: '80px',
                                      height: '20px',
                                      backgroundColor: make?.primaryColor,
                                    }}
                                  ></div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      width: '80px',
                                      height: '20px',
                                      backgroundColor: make?.secondaryColor,
                                    }}
                                  ></div>
                                </td>
                                <td>
                                  <Switch
                                    onChange={() => handleStatusToggle(make?._id, make?.status)}
                                    checked={make?.status === 'active'}
                                    onColor="#86d38a"
                                    offColor="#e44d4d"
                                    height={20}
                                    width={40}
                                  />
                                </td>
                                <td>
                                  <Button
                                    variant="warning"
                                    className="me-2 btn-sm"
                                    as={Link}
                                    to={editMakeLink(make?._id)}
                                  >
                                    <FaRegEdit />
                                  </Button>
                                  <Button
                                    variant="danger"
                                    onClick={() => handleDelete(make?._id)}
                                    className="btn-sm"
                                  >
                                    <FaTrashAlt />
                                  </Button>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-center">
                        <Pagination>
                          <Pagination.First onClick={() => paginate(1)} />
                          <Pagination.Prev
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                          />
                          {[...Array(totalPages).keys()].map((index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage}
                              onClick={() => paginate(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          />
                          <Pagination.Last onClick={() => paginate(totalPages)} />
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  )
}

export default ProjectList
