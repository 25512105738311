import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios'; // or fetch if you prefer

const MySwal = withReactContent(Swal);

function AddNewDealerURLPlan() {
  const navigate = useNavigate();

  // Form states
  const [planName, setPlanName] = useState('');
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState('month');

  // Handle saving the new plan
  const handleSave = async () => {
    // Simple validation
    if (!planName || !price || !duration) {
      MySwal.fire('Validation Error', 'All fields are required.', 'warning');
      return;
    }

    try {
      // Construct request body per your API spec
      const requestBody = {
        planName: planName,
        price: Number(price), // Ensure it's a number
        duration: duration
      };

      // Make POST request
      const response = await axios.post(
        'https://api.icarbuyer.co/api/create-page-for-dealer',
        requestBody
      );

      // Check if the API indicates success
      if (response.data.status === 1) {
        MySwal.fire(
          'Success',
          response.data.message || 'Plan has been successfully created.',
          'success'
        );
        // Navigate to plan list or wherever you want
        navigate('/dealerURLPlanList');
      } else {
        // Handle error case
        MySwal.fire(
          'Error',
          response.data.message || 'Failed to create plan.',
          'error'
        );
      }
    } catch (error) {
      console.error('Error saving new plan:', error);
      MySwal.fire(
        'Error',
        error.response?.data?.message || 'An error occurred.',
        'error'
      );
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">

          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">ADD NEW DEALER URL PLAN</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">
                      Add New Subscription Plan
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* Form Card */}
          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-2">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">
                    Subscription Plan Information
                  </h5>

                  <Form>
                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group controlId="planName">
                          <Form.Label>Plan Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter plan name..."
                            value={planName}
                            onChange={(e) => setPlanName(e.target.value)}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group controlId="planPrice">
                          <Form.Label>Price</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter plan price..."
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group controlId="planDuration">
                          <Form.Label>Duration</Form.Label>
                          <Form.Select
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                          >
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Action Buttons */}
                    <div className="text-end">
                      <Button
                        variant="outline-info"
                        className="min-btn m-2"
                        onClick={() => navigate('/planList')}
                      >
                        Back
                      </Button>
                      <Button
                        variant="info"
                        className="min-btn m-2"
                        onClick={handleSave}
                      >
                        SAVE
                      </Button>
                    </div>
                  </Form>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default AddNewDealerURLPlan;
