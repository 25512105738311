import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaEye, FaRegEdit, FaTrashAlt, FaRegCopy, FaCloudUploadAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Container, Dropdown, Row, Col, Table, Button, InputGroup, FormControl, Pagination, Spinner, Form, Modal } from 'react-bootstrap'

const MySwal = withReactContent(Swal)

const DealerCarList = () => {
  const { id } = useParams()
  const [carList, setCarList] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [loading, setLoading] = useState(true)
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [uploadMessage, setUploadMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const fetchCarlist = async () => {
    setLoading(true)
    try {
      const response = await fetch(`https://api.icarbuyer.co/api/dealer/allCarModelDetails/${id}?page=${currentPage}&limit=${itemsPerPage}`)
      const data = await response.json()
      setCarList(data.data)
      setTotalPages(data.pagination.totalPages)
    } catch (error) {
      console.error('Error fetching car details:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchCarDetails = async () => {
      setLoading(true)
      try {
        const response = await fetch(`https://api.icarbuyer.co/api/dealer/allCarModelDetails/${id}?page=${currentPage}&limit=${itemsPerPage}`)
        const data = await response.json()
        setCarList(data.data)
        setTotalPages(data.pagination.totalPages)
      } catch (error) {
        console.error('Error fetching car details:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchCarDetails()
  }, [id, currentPage, itemsPerPage])

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleItemsPerPageChange = (selectedItemsPerPage) => {
    setItemsPerPage(selectedItemsPerPage)
    setCurrentPage(1) // Reset to the first page when items per page change
  }

  // Handle file change
  const handleFileChange = (event) => {
    setFile(event.target.files[0])
  }

  // Handle bulk upload
  const handleBulkUpload = async () => {
    if (!file) {
      MySwal.fire('Error', 'Please select a file first!', 'error')
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    setUploading(true)

    try {
      const response = await fetch(`https://api.icarbuyer.co/api/dealer/upload-bulk-car-csv/${id}`, {
        method: 'POST',
        body: formData
      })
      const data = await response.json()

      if (data.status === 1) {
        setUploadMessage(`Cars successfully uploaded. Total Records: ${data.totalRecords}, New Records: ${data.newRecords}, Duplicate Records: ${data.duplicateRecords}`)
        MySwal.fire('Success', 'Cars successfully uploaded!', 'success')
        // Optionally reload the car list after upload
        fetchCarlist(); // You can update the car list if new records are fetched
      } else {
        MySwal.fire('Error', 'Failed to upload cars.', 'error')
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      MySwal.fire('Error', 'Something went wrong while uploading the file.', 'error')
    } finally {
      setUploading(false)
      setShowModal(false) // Close modal after upload
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <div className="card custom-shadow rounded-lg border">
                  <div className="card-body">
                    <Row>
                      <Col xs={6} className="mb-3">
                        <Button variant="info" as={Link} to={`/Add-Dealer-Car-Details/${id}`}>Add New Car</Button>
                      </Col>
                      <Col xs={3}>
                        <InputGroup className="mb-3">
                          <FormControl placeholder="Search..." value={searchTerm} onChange={handleSearch} />
                        </InputGroup>
                      </Col>
                      <Col xs={3} className="text-right">
                        <Button variant="success" onClick={() => setShowModal(true)}>
                          <FaCloudUploadAlt /> Upload Bulk CSV
                        </Button>
                      </Col>
                    </Row>
                    <Table striped bordered responsive>
                      <thead>
                        <tr>
                          <th>Model</th>
                          <th>Make</th>
                          <th>Vehicle Type</th>
                          <th>Trim</th>
                          <th>Year</th>
                          <th>Type</th>
                          <th>Base Price</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {carList.map((car) => (
                          <tr key={car?._id}>
                            <td>{car?.model}</td>
                            <td>{car?.make}</td>
                            <td>{car?.vehicleType}</td>
                            <td>{car?.trim}</td>
                            <td>{car?.year}</td>
                            <td>{car?.type}</td>
                            <td>{car?.basePrice}</td>
                            <td>
                              <Link to={`/viewDealerCar/${car?._id}`} title="View Car Details">
                                <Button variant="outline-info" size="sm" className="mx-2"><FaEye /></Button>
                              </Link>
                              <Link to={`/Add-Dealer-Car-Details-Copy/${car?._id}/${id}`} title="Copy Car Details">
                                <Button variant="outline-info" size="sm"><FaRegCopy /></Button>
                              </Link>
                              <Link to={`/Edit-Car-Details/${car?._id}/${id}`} title="Edit Car Details">
                                <Button variant="outline-info" size="sm" className="mx-2"><FaRegEdit /></Button>
                              </Link>
                              <Button variant="outline-danger" size="sm" title="Delete Car Record">
                                <FaTrashAlt />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Dropdown onSelect={handleItemsPerPageChange} className="mx-1">
                      <Dropdown.Toggle variant="info">{itemsPerPage}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey={10}>10</Dropdown.Item>
                        <Dropdown.Item eventKey={20}>20</Dropdown.Item>
                        <Dropdown.Item eventKey={50}>50</Dropdown.Item>
                        <Dropdown.Item eventKey={100}>100</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Pagination>
                      <Pagination.First onClick={() => handlePageClick(1)} disabled={currentPage === 1} />
                      <Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1} />
                      {[1, 2, 3].map((page) => (
                        <Pagination.Item key={page} active={page === currentPage} onClick={() => handlePageClick(page)}>
                          {page}
                        </Pagination.Item>
                      ))}
                      {totalPages > 3 && <Pagination.Ellipsis disabled />}
                      <Pagination.Item active={totalPages === currentPage} onClick={() => handlePageClick(totalPages)}>
                        {totalPages}
                      </Pagination.Item>
                      <Pagination.Next onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages} />
                      <Pagination.Last onClick={() => handlePageClick(totalPages)} disabled={currentPage === totalPages} />
                    </Pagination>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      {/* Modal for File Upload */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Bulk Cars CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="fileUpload">
              <Form.Label>Select CSV File</Form.Label>
              <Form.Control type="file" accept=".csv" onChange={handleFileChange} />
            </Form.Group>
            {uploading ? (
              <Button variant="primary" disabled className='my-1'>
                <Spinner animation="border" size="sm" /> Uploading...
              </Button>
            ) : (
              <Button variant="primary" onClick={handleBulkUpload} className='my-1'>
                <FaCloudUploadAlt /> Upload
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DealerCarList
