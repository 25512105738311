import React, { useState, useCallback, useEffect } from 'react'
import { FaEye, FaCarAlt, FaLocationArrow, FaClipboardList, FaTruck, FaTrash } from 'react-icons/fa'
import Switch from 'react-switch'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Modal,
  Form,
  Dropdown,
} from 'react-bootstrap'
import { Constants } from 'src/Constants'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'

const MySwal = withReactContent(Swal)

const CustomerList = () => {
  const [customers, setCustomers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const location = useLocation()

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')

  const [showModal, setShowModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [showStatusModal, setShowStatusModal] = useState(false);
const [selectedCustomerId, setSelectedCustomerId] = useState(null);
const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(Constants.getAllDealers)
        if (response.ok) {
          const data = await response.json()
          setCustomers(data)
        } else {
          console.error('Failed to fetch customers')
        }
      } catch (error) {
        console.error('Error fetching customers:', error)
      }
    }

    fetchCustomers()
  }, [])

  const handleStatusChange = async () => {
    if (!selectedCustomerId || !selectedStatus) {
      MySwal.fire('Error', 'Please select a status.', 'error');
      return;
    }
  
    try {
      const response = await fetch(`https://api.icarbuyer.co/api/dealer/update-profile-status/${selectedCustomerId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ adminApprovalStatus: selectedStatus }),
      });
  
      if (response.ok) {
        const result = await response.json();
        MySwal.fire('Success', result.message, 'success');
        setCustomers((prevCustomers) =>
          prevCustomers.map((customer) =>
            customer._id === selectedCustomerId
              ? { ...customer, adminApprovalStatus: selectedStatus }
              : customer,
          ),
        );
        setShowStatusModal(false);
        setSelectedStatus('')
      } else {
        throw new Error('Failed to update status.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
      MySwal.fire('Error', 'Failed to update status.', 'error');
    }
  };
    const handleDeleteUser = async (userId) => {
      try {
        const response = await fetch(`${Constants.deleteDealer}${userId}`, {
          method: 'DELETE',
        })
        if (response.ok) {
          setCustomers(customers.filter((customer) => customer._id !== userId))
          MySwal.fire({
            icon: 'success',
            title: 'Dealer information has Deleted!',
            text: 'The user has been successfully deleted.',
          })
        } else {
          console.error('Failed to delete user')
        }
      } catch (error) {
        console.error('Error deleting user:', error)
      }
    }

  const decodeHtmlEntities = (str) => {
    const textArea = document.createElement('textarea')
    textArea.innerHTML = str
    return textArea.value
  }

  const stripHtmlTags = (str) => {
    if (!str) return ''
    return str.replace(/<\/?[^>]+(>|$)/g, '') // Removes HTML tags
  }

  const escapeCsvValue = (value) => {
    if (!value) return ''
    const sanitizedValue = String(value)
      .replace(/[\r\n]/g, ' ') // Remove newlines
      .replace(/"/g, '""') // Escape double quotes
    return `"${sanitizedValue}"` // Enclose in double quotes
  }

  const getTodayDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const exportToCSV = () => {
    const headers = [
      'ID',
      'Name',
      'Category',
      'Phone',
      'Email',
      'Address',
      'Suburb',
      'Province',
      'Post Codes',
      'Division',
      'Website',
      'Fax',
      'TollFree',
      'Twitter',
      'Youtube',
      'Facebook',
      'Other Social',
      'Status',
    ]

    const csvRows = [
      headers.join(','),
      ...customers.map((customer) =>
        [
          escapeCsvValue(customer._id),
          escapeCsvValue(
            stripHtmlTags(decodeHtmlEntities(customer.businessName?.replace(/_/g, ' '))),
          ),
          escapeCsvValue(stripHtmlTags(decodeHtmlEntities(customer.category))),
          escapeCsvValue(customer.phone),
          escapeCsvValue(customer.email),
          escapeCsvValue(stripHtmlTags(decodeHtmlEntities(customer.address))),
          escapeCsvValue(customer.suburb),
          escapeCsvValue(customer.province),
          escapeCsvValue(customer.postCodes),
          escapeCsvValue(customer.division),
          escapeCsvValue(customer.website),
          escapeCsvValue(customer.fax),
          escapeCsvValue(customer.tollFree),
          escapeCsvValue(customer.twitter),
          escapeCsvValue(customer.youtube),
          escapeCsvValue(customer.facebook),
          escapeCsvValue(customer.otherSocial),
          escapeCsvValue(customer.status),
        ].join(','),
      ),
    ]

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    const todayDate = getTodayDate()
    link.href = url
    link.download = `dealers_list_${todayDate}.csv`
    link.click()
    window.URL.revokeObjectURL(url)
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value))
    setCurrentPage(1)
  }

  const sortedCustomers = customers.sort((a, b) => {
    const columnA = a[sortColumn]
    const columnB = b[sortColumn]

    if (columnA && columnB) {
      const valueA = typeof columnA === 'string' ? columnA : String(columnA)
      const valueB = typeof columnB === 'string' ? columnB : String(columnB)

      if (sortOrder === 'asc') {
        return valueA.localeCompare(valueB)
      } else {
        return valueB.localeCompare(valueA)
      }
    }

    return 0
  })

  const filteredCustomers = sortedCustomers.filter((customer) =>
    `${customer.firstName} ${customer.lastName} ${customer.email} ${customer.company} ${customer.status} ${customer.carCompany}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredCustomers.slice(indexOfFirstItem, indexOfLastItem)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const handleStatusToggle = useCallback((id, currentStatus) => {
    fetch(`${Constants.updateDealerStatus}${id}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: currentStatus === 'active' ? 'inactive' : 'active' }),
    })
      .then((response) => {
        if (response.ok) {
          setCustomers((prevCustomers) =>
            prevCustomers.map((customer) =>
              customer._id === id
                ? { ...customer, status: currentStatus === 'active' ? 'inactive' : 'active' }
                : customer,
            ),
          )
          MySwal.fire('Updated!', 'Dealer status has been updated.', 'success')
        } else {
          throw new Error('Failed to update Dealer status.')
        }
      })
      .catch((error) => {
        console.error('Error updating Dealer status:', error)
        MySwal.fire('Error', 'Failed to update Dealer status.', 'error')
      })
  }, [])

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(customers.length / itemsPerPage)
    const pageNumbers = []

    let startPage, endPage
    if (totalPages <= 5) {
      startPage = 1
      endPage = totalPages
    } else {
      if (currentPage <= 3) {
        startPage = 1
        endPage = 5
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4
        endPage = totalPages
      } else {
        startPage = currentPage - 2
        endPage = currentPage + 2
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
          {i}
        </Pagination.Item>,
      )
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <Pagination.Item
          key="prev"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <AiOutlineArrowLeft />
        </Pagination.Item>,
        <Pagination.Item key="first" onClick={() => paginate(1)}>
          1
        </Pagination.Item>,
        <Pagination.Ellipsis key="ellipsis-prev" />,
      )
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <Pagination.Ellipsis key="ellipsis-next" />,
        <Pagination.Item key="last" onClick={() => paginate(totalPages)}>
          {totalPages}
        </Pagination.Item>,
        <Pagination.Item
          key="next"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <AiOutlineArrowRight />
        </Pagination.Item>,
      )
    }

    return pageNumbers
  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const handleFileUpload = async () => {
    if (!selectedFile) {
      MySwal.fire('Error', 'Please select a file.', 'error')
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile)

    try {
      const response = await fetch('https://api.icarbuyer.co/api/dealer/upload-dealers', {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        const result = await response.json()
        MySwal.fire('Success', 'Dealers uploaded successfully.', 'success')
        setShowModal(false)
      } else {
        throw new Error('Failed to upload file.')
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      MySwal.fire('Error', 'Failed to upload file.', 'error')
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">DEALER LIST</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-info active">Dealer List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">Dealer Account List </h5>
                  <Row>
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <h3 className="fw-bolder text-info mb-0">{customers.length}</h3>
                      <p className="text-secondary">Total Dealers</p>
                    </Col>
                    {/* <Col md={4} xs={12} className="mb-3 text-center">
                      <Button className="ml-2" variant="info" onClick={() => setShowModal(true)}>
                        Upload Dealer CSV
                      </Button>
                    </Col> */}
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <Button variant="success" onClick={exportToCSV}>
                        Export to CSV
                      </Button>
                    </Col>
                  </Row>

                  <div className="dataTables_length mb-2 d-flex justify-content-between align-items-center">
                    <div className="search-box">
                      <input
                        type="text"
                        id="search"
                        placeholder="Search "
                        name="search"
                        className="form-control "
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="mx-1">Show</span>
                      <Dropdown onSelect={handleItemsPerPageChange} className="mx-1">
                        <Dropdown.Toggle variant="info" id="dropdown-basic">
                          {itemsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey={50}>50</Dropdown.Item>
                          <Dropdown.Item eventKey={100}>100</Dropdown.Item>
                          <Dropdown.Item eventKey={500}>500</Dropdown.Item>
                          <Dropdown.Item eventKey={1000}>1000</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <span>items per page</span>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort('country')}>
                            Name of Dealership
                            {sortColumn === 'country' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          
                          <th onClick={() => handleSort('lastName')}>
                            Telephone No.
                            {sortColumn === 'lastName' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('email')}>
                            Email
                            {sortColumn === 'email' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('leadBalanceWallet')}>
                            Lead Balance
                            {sortColumn === 'leadBalanceWallet' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('adminApprovalStatus')}>
                            Admin Approval Status
                            {sortColumn === 'adminApprovalStatus' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems?.map((customer, index) => (
                          <tr key={customer?._id}>
                            <td>{customer?.businessName.replace(/_/g, ' ')}</td>
                        
                            <td>{customer?.phone}</td>
                            <td>{customer?.email}</td>
                            <td>{customer?.leadBalanceWallet}</td>
                            <td>
  {customer?.adminApprovalStatus?.charAt(0).toUpperCase() + customer?.adminApprovalStatus?.slice(1).toLowerCase()}
 
    <Button
      variant="info"
      size="sm"
      onClick={() => {
        setSelectedCustomerId(customer._id);
        setShowStatusModal(true);
      }}
    >
      Change Status
    </Button>
 
</td>

                            <td>
                              <Switch
                                onChange={() => handleStatusToggle(customer._id, customer.status)}
                                checked={customer.status === 'active'}
                                onColor="#86d38a"
                                offColor="#e44d4d"
                                height={20}
                                width={40}
                              />
                            </td>
                            <td>
                              <div className="icon-container">
                                <Link
                                  className="btn btn-sm btn-primary text-white"
                                  title="Add Makes & Vehicle Types"
                                  to={`/dealerAction/${customer._id}`}
                                >
                                  <FaClipboardList />
                                </Link>
                                <Link
                                  className="btn btn-sm btn-warning text-white"
                                  title="Add Cars Models"
                                  to={`/addDealerCar/${customer._id}`}
                                >
                                  <FaTruck />
                                </Link>
                                <Link
                                  className="btn btn-sm btn-success text-white"
                                  title="Dealer Car Lists"
                                  to={`/Dealer-Car-Lists/${customer._id}`}
                                >
                                  <FaCarAlt />
                                </Link>
                                <Link
                                  className="btn btn-sm btn-dark text-white"
                                  title="Add Provinces & Postal Codes"
                                  to={`/addDealerPostCodes/${customer._id}`}
                                >
                                  <FaLocationArrow />
                                </Link>
                                <Link
                                  className="btn btn-sm btn-info text-white"
                                  title="View Dealer Details"
                                  to={`/viewDealer/${customer._id}`}
                                >
                                  <FaEye />
                                </Link>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  title="Delete User"
                                  onClick={() => {
                                    MySwal.fire({
                                      title: 'Are you sure?',
                                      text: 'Once deleted, you will not be able to recover this dealer!',
                                      icon: 'warning',
                                      showCancelButton: true,
                                      confirmButtonText: 'Yes, delete it!',
                                      cancelButtonText: 'No, cancel!',
                                      reverseButtons: true,
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDeleteUser(customer._id)
                                      }
                                    })
                                  }}
                                >
                                  <FaTrash />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Container>
                    <div className="col-sm-12 col-md-5">
                      <div className="dataTables_length">
                        Showing {indexOfFirstItem + 1} to{' '}
                        {Math.min(indexOfLastItem, customers.length)} of {customers.length} entries
                      </div>
                    </div>
                    <Pagination>{renderPaginationItems()}</Pagination>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Dealer CSV</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFile">
                <Form.Label>Choose CSV file</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFileUpload}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Change Approval Status</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="formStatus">
        <Form.Label>Select Status</Form.Label>
        <Form.Control
          as="select"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          
        >
           <option value="">Select Status</option>
          <option value="approved">Approve</option>
          <option value="rejected">Reject</option>
        </Form.Control>
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowStatusModal(false)}>
      Cancel
    </Button>
    <Button variant="primary" onClick={handleStatusChange}>
      Confirm
    </Button>
  </Modal.Footer>
</Modal>
      </div>
    </div>
  )
}

export default CustomerList
