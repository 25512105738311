import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Pagination,
  Dropdown,
} from 'react-bootstrap'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const VehicleTypeList = () => {
  const [makesData, setMakesData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(100)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(Constants.getAllVehicleTypes)
      .then((response) => response.json())
      .then((data) => {
        setMakesData(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching Vehicle Types:', error)
        setLoading(false)
      })
  }, [])

  const exportToCSV = () => {
    const headers = ['Id', 'Vehicle Type', 'Status']
    const csvRows = [headers.join(','), ...makesData.map((make) => `${make._id},${make.name},${make.status}`)]

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'vehicle_types.csv'
    link.click()
    window.URL.revokeObjectURL(url)
  }

  const handleStatusToggle = (id, currentStatus) => {
    fetch(`${Constants.updateVehicleTypeStatus + id}/status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: currentStatus === 'active' ? 'inactive' : 'active' }),
    })
      .then((response) => {
        if (response.ok) {
          setMakesData((prevMakesData) =>
            prevMakesData.map((make) =>
              make._id === id
                ? { ...make, status: currentStatus === 'active' ? 'inactive' : 'active' }
                : make,
            ),
          )
          MySwal.fire('Updated!', 'Vehicle Type status has been updated.', 'success')
        } else {
          throw new Error('Failed to update Vehicle Type status.')
        }
      })
      .catch((error) => {
        console.error('Error updating Vehicle Type status:', error)
        MySwal.fire('Error', 'Failed to update Vehicle Type status.', 'error')
      })
  }

  const handleDelete = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this Vehicle Type!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(Constants.deleteVehicleTypebyId + id, {
          method: 'DELETE',
        })
          .then((response) => {
            if (response.ok) {
              setMakesData((prevMakesData) => prevMakesData.filter((make) => make._id !== id))
              MySwal.fire('Deleted!', 'Vehicle Type has been deleted.', 'success')
            } else {
              throw new Error('Failed to delete Vehicle Type.')
            }
          })
          .catch((error) => {
            console.error('Error deleting Vehicle Type:', error)
            MySwal.fire('Error', 'Failed to delete Vehicle Type.', 'error')
          })
      }
    })
  }

  const handleItemsPerPageChange = (eventKey) => {
    setItemsPerPage(parseInt(eventKey, 10))
    setCurrentPage(1) // Reset to first page when items per page changes
  }

  const filteredMakes = makesData.filter((make) =>
    make.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredMakes.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(filteredMakes.length / itemsPerPage)

  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return
    setCurrentPage(pageNumber)
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">VEHICLE TYPE</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Vehicle Type</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <div className="card custom-shadow rounded-lg border">
                  <div className="card-body">
                    <Row className="d-flex justify-content-between align-items-center">
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <h3 className="fw-bolder text-info mb-0"> {filteredMakes?.length} </h3>
                        <p className="text-secondary">Total Vehicle Types</p>
                      </Col>

                      <Col md={4} xs={12} className="mb-3 text-center">
                        <Button className="mb-3" variant="info" as={Link} to="/addNewVehicleType">
                          Add New Vehicle Type
                        </Button>
                      </Col>
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <Button variant="success" onClick={exportToCSV}>
                          Export to CSV
                        </Button>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="search-box">
                        <input
                          type="text"
                          id="search"
                          placeholder="Search Vehicle Types..."
                          name="search"
                          className="form-control"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        <span className="mx-1">Show </span>
                        <Dropdown onSelect={handleItemsPerPageChange} className="mx-1">
                          <Dropdown.Toggle variant="info" id="dropdown-basic">
                            {itemsPerPage}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey={10}>10</Dropdown.Item>
                            <Dropdown.Item eventKey={25}>25</Dropdown.Item>
                            <Dropdown.Item eventKey={50}>50</Dropdown.Item>
                            <Dropdown.Item eventKey={100}>100</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <span> items per page</span>
                      </div>
                    </div>
                    <div>
                      <Table responsive bordered hover>
                        <thead>
                          <tr>
                            <th>Vehicle Types</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.map((make) => (
                            <tr key={make?._id}>
                              <td>{make?.name}</td>
                              <td>
                                <Switch
                                  onChange={() => handleStatusToggle(make?._id, make?.status)}
                                  checked={make?.status === 'active'}
                                  onColor="#86d38a"
                                  offColor="#e44d4d"
                                  height={20}
                                  width={40}
                                />
                              </td>
                              <td>
                                <Button
                                  variant="warning"
                                  className="me-2 btn-sm"
                                  as={Link}
                                  to={`/editVehicle/${make?._id}`}
                                >
                                  <FaRegEdit />
                                </Button>
                                <Button
                                  variant="danger"
                                  onClick={() => handleDelete(make?._id)}
                                  className="btn-sm"
                                >
                                  <FaTrashAlt />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-center">
                        <Pagination>
                          <Pagination.First onClick={() => paginate(1)} />
                          <Pagination.Prev
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                          />
                          {[...Array(totalPages).keys()].map((index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage}
                              onClick={() => paginate(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          />
                          <Pagination.Last onClick={() => paginate(totalPages)} />
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  )
}

export default VehicleTypeList
