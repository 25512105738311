import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaEye, FaRegEdit, FaSearch, FaTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  FormControl,
  Pagination,
  Spinner,
} from 'react-bootstrap'
import { Constants } from 'src/Constants' // Ensure your Constants file has addNewPlan, getAllPlans, etc.

const MySwal = withReactContent(Swal)

const PlanList = () => {
  // State for plans
  const [planData, setPlanData] = useState([])
  // State for search term
  const [searchTerm, setSearchTerm] = useState('')
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  // State for sorting
  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')
  // Loading state
  const [loading, setLoading] = useState(true)

  // Fetch all subscription plans on component mount
  useEffect(() => {
    fetch('https://api.icarbuyer.co/api/plans') // or Constants.getAllPlans if defined
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 1 && Array.isArray(data.list)) {
          setPlanData(data.list)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching plan data:', error)
        setLoading(false)
      })
  }, [])

  /**
   * Delete a plan by ID
   */
  const handleDelete = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this plan!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://api.icarbuyer.co/api/plans/${id}`, {
          method: 'DELETE',
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 1) {
              MySwal.fire('Deleted!', 'The plan has been deleted.', 'success')
              // Remove the deleted plan from state
              setPlanData((prevData) => prevData.filter((plan) => plan._id !== id))
            } else {
              MySwal.fire('Error!', data.message || 'Failed to delete the plan.', 'error')
            }
          })
          .catch((error) => {
            console.error('Error deleting plan:', error)
            MySwal.fire('Error!', 'An error occurred while deleting the plan.', 'error')
          })
      }
    })
  }

  /**
   * Sort handler
   */
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }

  /**
   * Filter plans by search term
   */
  const filteredPlans = planData.filter((plan) =>
    Object.values(plan).some(
      (value) =>
        value &&
        typeof value === 'string' &&
        value.toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )

  /**
   * Sort the filtered plans
   */
  const sortedPlans = [...filteredPlans].sort((a, b) => {
    if (!sortColumn) return 0 // If no sorting column selected

    const columnA = a[sortColumn]
    const columnB = b[sortColumn]

    if (typeof columnA === 'string' && typeof columnB === 'string') {
      return sortOrder === 'asc'
        ? columnA.localeCompare(columnB)
        : columnB.localeCompare(columnA)
    } else if (typeof columnA === 'number' && typeof columnB === 'number') {
      return sortOrder === 'asc' ? columnA - columnB : columnB - columnA
    } else {
      // Fallback if the column type is mixed or undefined
      return 0
    }
  })

  /**
   * Pagination calculations
   */
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = sortedPlans.slice(indexOfFirstItem, indexOfLastItem)

  /**
   * Handle pagination click
   */
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">LEAD PLAN LIST</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-info active">Lead Plan List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          {/* Loading Spinner */}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <div className="card custom-shadow rounded-lg border">
                  <div className="card-body">
                    <Row>
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <h3 className="fw-bolder text-info mb-0">{planData.length}</h3>
                        <p className="text-secondary">Total Subscription Plans</p>
                      </Col>
                    </Row>

                    {/* Search Input */}
                    <div style={{ width: '30%' }}>
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder="Search..."
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <InputGroup.Text id="basic-addon2">
                          <FaSearch />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>

                    {/* Plans Table */}
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort('planName')}>
                            Plan Name
                            {sortColumn === 'planName' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('price')}>
                            Price (Monthly)
                            {sortColumn === 'price' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('leads')}>
                            Leads/Month
                            {sortColumn === 'leads' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('additionalLeadPrice')}>
                            Additional Lead Price
                            {sortColumn === 'additionalLeadPrice' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('productId')}>
                           Product ID
                            {sortColumn === 'productId' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('priceId')}>
                           Price ID
                            {sortColumn === 'priceId' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('createdAt')}>
                            Created Date
                            {sortColumn === 'createdAt' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((plan) => (
                          <tr key={plan._id}>
                            <td>{plan.name}</td>
                            <td>$ {plan.price}</td>
                            <td>{plan.leads}</td>
                            <td>$ {plan.additionalLeadPrice}</td>
                            <td> {plan.productId}</td>
                            <td>{plan.priceId}</td>
                            <td>
                              {plan.createdAt
                                ? new Date(plan.createdAt).toLocaleDateString()
                                : 'N/A'}
                            </td>
                            <td>
                              {/* View Button (if you want a detail page) */}
                              <Link to={`/viewPlan/${plan._id}`}>
                                <Button variant="outline-info" size="sm" className="mx-1">
                                  <FaEye />
                                </Button>
                              </Link>
                              {/* Edit Button (if you plan to implement edit) */}
                              {/* <Link to={`/editPlan/${plan._id}`}>
                                <Button variant="outline-primary" size="sm" className="mx-1">
                                  <FaRegEdit />
                                </Button>
                              </Link> */}
                              {/* Delete Button */}
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() => handleDelete(plan._id)}
                              >
                                <FaTrashAlt />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {/* Pagination */}
                    <Pagination>
                      {Array.from({
                        length: Math.ceil(sortedPlans.length / itemsPerPage),
                      }).map((_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                    </Pagination>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  )
}

export default PlanList
