import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { FaEye, FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  Pagination,
  Dropdown,
  Spinner,
  Modal,
} from 'react-bootstrap'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const CarList = () => {
  const [carList, setCarList] = useState([])
  const [makeFilter, setMakeFilter] = useState('')
  const [vehicleTypeFilter, setVehicleTypeFilter] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [loading, setLoading] = useState(true)

  const [showModal, setShowModal] = useState(false) // Modal for uploading CSV
  const [showResponseModal, setShowResponseModal] = useState(false) // Modal to display server response
  const [selectedFile, setSelectedFile] = useState(null)
  const [responseData, setResponseData] = useState(null) // Store API response after CSV upload
  const [makes, setMakes] = useState([])
  const [vehicleTypes, setVehicleTypes] = useState([])
  useEffect(() => {
    const fetchMakes = async () => {
      try {
        const response = await fetch('https://api.icarbuyer.co/api/make/allMakes') // Add this to your Constants
        const data = await response.json()
        setMakes(data)
      } catch (error) {
        console.error('Error fetching makes:', error)
      }
    }

    const fetchVehicleTypes = async () => {
      try {
        const response = await fetch('https://api.icarbuyer.co/api/vehicle/vehicleType') // Add this to your Constants
        const data = await response.json()
        setVehicleTypes(data)
      } catch (error) {
        console.error('Error fetching vehicle types:', error)
      }
    }

    fetchMakes()
    fetchVehicleTypes()
  }, [])

  const fetchCarData = useCallback(async () => {
    try {
      setLoading(true)
      const params = new URLSearchParams()
      params.append('make', makeFilter)
      params.append('vehicleType', vehicleTypeFilter)
      params.append('search', searchTerm)
      params.append('page', currentPage)
      params.append('limit', itemsPerPage)

      const url = `https://api.icarbuyer.co/api/car/carWithFilter?${params.toString()}`
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const data = await response.json()

      if (data.status === 1) {
        setCarList(data.data)
        setTotalPages(data.pagination.totalPages)
        setTotalRecords(data.pagination.totalRecords)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error fetching car data:', error.message)
      setLoading(false)
    }
  }, [makeFilter, vehicleTypeFilter, searchTerm, currentPage, itemsPerPage])

  useEffect(() => {
    fetchCarData()
  }, [fetchCarData])

  const handleDelete = async (carId) => {
    try {
      const result = await MySwal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this car data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
      })

      if (result.isConfirmed) {
        const response = await fetch(Constants.deleteCarById + carId, {
          method: 'DELETE',
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        // Refresh data after deletion
        await fetchCarData()

        MySwal.fire({
          icon: 'success',
          title: 'Car Deleted Successfully!',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    } catch (error) {
      console.error(`Error deleting car with ID ${carId}:`, error.message)
    }
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const handleMakeFilterChange = (e) => {
    setMakeFilter(e.target.value)
    setCurrentPage(1)
  }

  const handleVehicleTypeFilterChange = (e) => {
    setVehicleTypeFilter(e.target.value)
    setCurrentPage(1)
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleItemsPerPageChange = (selectedItemsPerPage) => {
    setItemsPerPage(Number(selectedItemsPerPage))
    setCurrentPage(1)
  }

  // Pagination range calculation
  const pageRange = 3
  const startPage = Math.max(1, currentPage - pageRange)
  const endPage = Math.min(totalPages, currentPage + pageRange)

  const decodeHtmlEntities = (str) => {
    const textArea = document.createElement('textarea')
    textArea.innerHTML = str
    return textArea.value
  }

  const stripHtmlTags = (str) => {
    if (!str) return ''
    return str.replace(/<\/?[^>]+(>|$)/g, '') // Removes HTML tags
  }

  const escapeCsvValue = (value) => {
    if (!value) return ''
    const sanitizedValue = String(value)
      .replace(/[\r\n]/g, ' ') // Remove newlines
      .replace(/"/g, '""') // Escape double quotes
    return `"${sanitizedValue}"` // Enclose in proper double quotes
  }

  const exportToCSV = () => {
    // 1) Define all columns, keeping your original CSV columns first
    //    and then appending all additional fields from the API.
    const headers = [
      // --- Original columns ---
      'Sl#',
      'Make*',
      'Vehicle Type*',
      'Model',
      'Trim',
      'Year',
      'Condition (New/Pre-Owned/Certified Pre-Owned)',
      'Base Price',
      'Rust Proofing',
      'Paint Protection',
      'Other',
      'Payable on Delivery',
      'Tax Rate (GST/HST)*',
      'Tax %',
      'Note',
      'Extras',
      'Side Steps',
      'Roof Racks',
      'Trailer Packages',
      'Spray Liners',

      // --- Additional fields from the API (in a logical order) ---
      'Mileage',
      'Rust Proofing Details',
      'Paint Protection Details',
      'Fabric Protection',
      'Fabric Protection Details',
      'Window Tinting',
      'Window Tinting Details',
      'Other Protection Packages',
      'Other Protection Packages Details',
      'Comprehensive Warranty',
      'Comprehensive Warranty Details',
      'Power Train Warranty',
      'Power Train Warranty Details',
      'Total Insurance',
      'Total Insurance Details',
      'Estimated Total Taxes And Fees',
      'Estimated Total Taxes And Fees Details',
      'Admin Fee',
      'Admin Fee Details',
      'Air Tax',
      'Air Tax Details',
      'Gasoline',
      'Gasoline Details',
      'Omvic Fee',
      'Omvic Fee Details',
      'Lien Registration Fee',
      'Lien Registration Fee Details',
      'License Fee',
      'License Fee Details',
      'Other Details',
      'Payable on Delivery Details',
      'Tire And Wheel',
      'Tire And Wheel Details',
      'Side Steps Details',
      'Roof Racks Details',
      'Trailer Packages Details',
      'Spray Liner Details',
      // If you want to include `image`, uncomment below:
      // "Image",
    ]

    // 2) Build CSV rows
    const csvRows = [
      // Header row
      headers.map((header) => escapeCsvValue(header)).join(','),

      // Data rows for each car
      ...carList.map((car, index) => {
        return [
          // --- Original columns (same order as headers) ---
          escapeCsvValue(index + 1), // Sl#
          escapeCsvValue(stripHtmlTags(decodeHtmlEntities(car?.make?.name || ''))),
          escapeCsvValue(stripHtmlTags(decodeHtmlEntities(car?.vehicleType?.name || ''))),
          escapeCsvValue(stripHtmlTags(decodeHtmlEntities(car?.model || ''))),
          escapeCsvValue(stripHtmlTags(decodeHtmlEntities(car?.trim || ''))),
          escapeCsvValue(car?.year ?? ''),
          escapeCsvValue(car?.type || 'New'),
          escapeCsvValue(car?.basePrice || ''),
          escapeCsvValue(car?.rustProofing || 'No'),
          escapeCsvValue(car?.paintProtection || 'No'),
          escapeCsvValue(car?.other || 'No'),
          escapeCsvValue(car?.payableOnDelivery || 'No'),
          escapeCsvValue(car?.taxRate || 'GST'),
          escapeCsvValue(car?.taxRatePercentage || ''),
          escapeCsvValue(stripHtmlTags(decodeHtmlEntities(car?.note || ''))),
          escapeCsvValue(stripHtmlTags(decodeHtmlEntities(car?.extras || ''))),
          escapeCsvValue(car?.sideSteps || 'No'),
          escapeCsvValue(car?.roofRacks || 'No'),
          escapeCsvValue(car?.trailerPackages || 'No'),
          escapeCsvValue(car?.sprayLiners || 'No'),

          // --- Additional fields ---
          escapeCsvValue(car?.mileage || ''),
          escapeCsvValue(car?.rustProofingDetails || ''),
          escapeCsvValue(car?.paintProtectionDetails || ''),
          escapeCsvValue(car?.fabricProtection || 'NO'),
          escapeCsvValue(car?.fabricProtectionDetails || ''),
          escapeCsvValue(car?.windowTinting || 'NO'),
          escapeCsvValue(car?.windowTintingDetails || ''),
          escapeCsvValue(car?.otherProtectionPackages || 'NO'),
          escapeCsvValue(car?.otherProtectionPackagesdetails || ''),
          escapeCsvValue(car?.comprehensiveWarranty || 'NO'),
          escapeCsvValue(car?.comprehensiveWarrantyDetails || ''),
          escapeCsvValue(car?.powerTrainWarranty || 'NO'),
          escapeCsvValue(car?.powerTrainWarrantyDetails || ''),
          escapeCsvValue(car?.totalInsurance || 'NO'),
          escapeCsvValue(car?.totalInsuranceDetails || ''),
          escapeCsvValue(car?.estimatedTotalTaxesAndFees || 'NO'),
          escapeCsvValue(car?.estimatedTotalTaxesAndFeesDetails || ''),
          escapeCsvValue(car?.adminFee || 'NO'),
          escapeCsvValue(car?.adminFeeDetails || ''),
          escapeCsvValue(car?.airTax || 'NO'),
          escapeCsvValue(car?.airTaxdetails || ''),
          escapeCsvValue(car?.gasoline || 'NO'),
          escapeCsvValue(car?.gasolineDetails || ''),
          escapeCsvValue(car?.omvicFee || 'NO'),
          escapeCsvValue(car?.omvicFeedetails || ''),
          escapeCsvValue(car?.lienRegistrationFee || 'NO'),
          escapeCsvValue(car?.lienRegistrationFeeDetails || ''),
          escapeCsvValue(car?.licenseFee || 'NO'),
          escapeCsvValue(car?.licenseFeeDetails || ''),
          escapeCsvValue(car?.otherDetails || ''),
          escapeCsvValue(car?.payableOnDeliveryDetails || ''),
          escapeCsvValue(car?.tireAndWheel || 'NO'),
          escapeCsvValue(car?.tireAndWheelDetails || ''),
          escapeCsvValue(car?.sideStepsdetails || ''),
          escapeCsvValue(car?.roofRacksDetails || ''),
          escapeCsvValue(car?.trailerPackagesdetails || ''),
          escapeCsvValue(car?.sprayLinerdetails || ''),
          // If you want `image`:
          // escapeCsvValue(car?.image || ""),
        ].join(',')
      }),
    ]

    // 3) Create CSV Blob and prompt user to download
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    const today = new Date()
    const dateStr = today.toISOString().slice(0, 10)
    link.href = url
    link.download = `car_list_${dateStr}.csv`
    link.click()

    window.URL.revokeObjectURL(url)
  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }

  // UPLOAD CSV FUNCTION
  const handleFileUpload = async () => {
    if (!selectedFile) {
      MySwal.fire('Error', 'Please select a CSV file.', 'error')
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile)

    try {
      const response = await fetch(Constants.uploadCarCSV, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        const result = await response.json()

        // If you want to update the car list upon upload (optional, depends on your use case):
        // setCarList(result) -- But your API is returning additional info,
        // so you might not want to override the entire carList here.

        // Close the upload modal
        setShowModal(false)

        // Set the response data to display in a new modal
        setResponseData(result)
        setShowResponseModal(true)
      } else {
        throw new Error('Failed to upload CSV.')
      }
    } catch (error) {
      console.error('Error uploading CSV:', error)
      MySwal.fire('Error', 'Failed to upload CSV.', 'error')
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">VEHICLE LIST</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Vehicle List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <div className="card custom-shadow rounded-lg border">
                  <div className="card-body">
                    <Row className="d-flex justify-content-between align-items-center">
                      <Col md={3} className="mb-3 text-center">
                        <h3 className="fw-bolder text-info mb-0">{totalRecords}</h3>
                        <p className="text-secondary">Total Vehicles</p>
                      </Col>
                      <Col md={3} className="mb-3">
                        <Form.Select value={makeFilter} onChange={handleMakeFilterChange}>
                          <option value="">All Makes</option>
                          {makes.map((make) => (
                            <option key={make._id} value={make.name}>
                              {make.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col md={3} className="mb-3">
                        <Form.Select
                          value={vehicleTypeFilter}
                          onChange={handleVehicleTypeFilterChange}
                        >
                          <option value="">All Vehicle Types</option>
                          {vehicleTypes.map((type) => (
                            <option key={type._id} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col md={3} xs={12} className="mb-3 text-center">
                        <Button className="ml-2" variant="info" onClick={() => setShowModal(true)}>
                          Upload Cars CSV
                        </Button>
                      </Col>
                      <Col md={3} xs={12} className="mb-3 text-center">
                        <Button variant="success" onClick={exportToCSV}>
                          Export to CSV
                        </Button>
                      </Col>
                      <Col md={3} xs={12} className="mb-3 text-center">
                        <Button variant="info" as={Link} to="/addNewCar">
                          Add New Car
                        </Button>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="search-box">
                        <Form.Control
                          type="text"
                          placeholder="Search "
                          value={searchTerm}
                          onChange={handleSearch}
                          style={{ width: '250px' }}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="me-2">Show</span>
                        <Dropdown onSelect={handleItemsPerPageChange}>
                          <Dropdown.Toggle variant="info" id="dropdown-basic">
                            {itemsPerPage}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="10">10</Dropdown.Item>
                            <Dropdown.Item eventKey="20">20</Dropdown.Item>
                            <Dropdown.Item eventKey="50">50</Dropdown.Item>
                            <Dropdown.Item eventKey="100">100</Dropdown.Item>
                            <Dropdown.Item eventKey="200">200</Dropdown.Item>
                            <Dropdown.Item eventKey="500">500</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <span className="ms-2">items per page</span>
                      </div>
                    </div>

                    <Table striped bordered responsive>
                      <thead>
                        <tr>
                          <th>Make</th>
                          <th width="150">Vehicle Type</th>
                          <th width="150">Model</th>
                          <th>Trim</th>
                          <th width="80" className="text-center">
                            Year
                          </th>
                          <th width="150" className="text-center">
                            Type
                          </th>
                          <th width="150" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {carList?.map((car) => (
                          <tr key={car?._id}>
                            <td>{car?.make?.name}</td>
                            <td>{car?.vehicleType?.name}</td>
                            <td>{car?.model}</td>
                            <td>{car?.trim}</td>
                            <td className="text-center">{car?.year}</td>
                            <td className="text-center">{car?.type}</td>
                            <td className="text-center">
                              <Button
                                variant="outline-info"
                                className="me-2 btn-sm"
                                as={Link}
                                to={`/viewCar/${car?._id}`}
                              >
                                <FaEye />
                              </Button>
                              <Button
                                variant="warning"
                                className="me-2 btn-sm"
                                as={Link}
                                to={`/editCar/${car?._id}`}
                              >
                                <FaRegEdit />
                              </Button>
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() => handleDelete(car._id)}
                              >
                                <FaTrashAlt />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-center">
                      <Pagination>
                        <Pagination.First
                          onClick={() => handlePageClick(1)}
                          disabled={currentPage === 1}
                        />
                        <Pagination.Prev
                          onClick={() => handlePageClick(currentPage - 1)}
                          disabled={currentPage === 1}
                        />
                        {[...Array(endPage - startPage + 1).keys()].map((index) => (
                          <Pagination.Item
                            key={startPage + index}
                            active={startPage + index === currentPage}
                            onClick={() => handlePageClick(startPage + index)}
                          >
                            {startPage + index}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={() => handlePageClick(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        />
                        <Pagination.Last
                          onClick={() => handlePageClick(totalPages)}
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
        {/* Modal for Uploading CSV */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Cars CSV</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFile">
                <Form.Label>Choose CSV file</Form.Label>
                <Form.Control type="file" accept=".csv" onChange={handleFileChange} />
              </Form.Group>
              <Button
                variant="link"
                className="mt-3"
                onClick={() => {
                  const link = document.createElement('a')
                  link.href = 'https://api.icarbuyer.co/static/Sample_Car_CSV.csv'
                  link.download = 'iCARBuyer-BulkUpload.csv'
                  link.click()
                }}
              >
                Download Sample CSV
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFileUpload}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal to display response after uploading CSV */}
        <Modal show={showResponseModal} onHide={() => setShowResponseModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>CSV Upload Result</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {responseData && (
              <>
                <p className="mb-1">
                  <strong>Status:</strong> {responseData.status === 1 ? 'Success' : 'Failure'}
                </p>
                <p className="mb-1">
                  <strong>Message:</strong> {responseData.message}
                </p>
                <p className="mb-1">
                  <strong>Total Records:</strong> {responseData.totalRecords}
                </p>
                <p className="mb-1">
                  <strong>New Records:</strong> {responseData.newRecords}
                </p>
                <p className="mb-1">
                  <strong>Duplicate Records:</strong> {responseData.duplicateRecords}
                </p>
                {responseData.duplicateDetails && responseData.duplicateDetails.length > 0 && (
                  <>
                    <hr />
                    <p>
                      <strong>Duplicate Details</strong>
                    </p>
                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                      {responseData.duplicateDetails.map((dup, idx) => (
                        <div key={idx} style={{ marginBottom: '0.5rem' }}>
                          <span style={{ fontWeight: 'bold' }}>Row {dup.row}:</span>
                          <br />
                          <span>
                            <strong>Duplicate Row:</strong> {dup.duplicateRow}
                          </span>
                          <br />
                          <span>
                            <strong>Make:</strong> {dup.details.make}
                          </span>
                          <br />
                          <span>
                            <strong>Vehicle Type:</strong> {dup.details.vehicleType}
                          </span>
                          <br />
                          <span>
                            <strong>Model:</strong> {dup.details.model}
                          </span>
                          <br />
                          <span>
                            <strong>Trim:</strong> {dup.details.trim}
                          </span>
                          <br />
                          <span>
                            <strong>Year:</strong> {dup.details.year}
                          </span>
                          <br />
                          <span>
                            <strong>Base Price:</strong> {dup.details.basePrice}
                          </span>
                          <br />
                          <span>
                            <strong>Type:</strong> {dup.details.type}
                          </span>
                          <br />
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {responseData.errors && responseData.errors.length > 0 && (
                  <>
                    <hr />
                    <p>
                      <strong>Errors</strong>
                    </p>
                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                      {responseData.errors.map((err, idx) => (
                        <div key={idx} style={{ marginBottom: '0.5rem' }}>
                          <span style={{ fontWeight: 'bold' }}>Row {err.row}:</span> {err.message}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowResponseModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default CarList
