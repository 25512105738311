import React from 'react'
import { useState, useRef, useEffect } from 'react'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
import Stepper from 'react-stepper-horizontal'
import { Constants } from 'src/Constants'
import axios from 'axios'
import { Form, ListGroup } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const DealerCarAddCopy = () => {
    const [id, setId] = useState('');
  const [dealerID, setDealerID] = useState('');
  const [makes, setMakes] = useState([])
  const [selectedMake, setSelectedMake] = useState('')
  const [vehicleTypes, setVehicleTypes] = useState([])
  const [carModels, setCarModels] = useState([])
  const [initialCarModelDetails, setInitialCarModelDetails] = useState(null);
  const [carModelDetails, setCarModelDetails] = useState({
    make: '',
    vehicleType: '',
    model: '',
    trim: '',
    year: '',
    type: '',
    mileage: '',
    rustProofingDetails: '',
    paintProtectionDetails: '',
    fabricProtectionDetails: '',
    windowTintingDetails: '',
    otherProtectionPackagesdetails: '',
    comprehensiveWarrantyDetails: '',
    powerTrainWarrantyDetails: '',
    totalInsuranceDetails: '',
    estimatedTotalTaxesAndFeesDetails: '',
    adminFeeDetails: '',
    airTaxdetails: '',
    gasolineDetails: '',
    omvicFeedetails: '',
    lienRegistrationFeeDetails: '',
    licenseFeeDetails: '',
    otherDetails: '',
    payableOnDeliveryDetails: '',
    taxRate: '',
    taxRatePercentage: '',
    note: '',
    tireAndWheelDetails: '',
    sideStepsdetails: '',
    roofRacksDetails: '',
    trailerPackagesdetails: '',
    sprayLinerdetails: '',
    basePrice: '',
  })
  useEffect(() => {
    // Function to extract id and dealerID from the URL
    const extractIDsFromURL = () => {
      const url = window.location.href;
      const urlParts = url.split('/');
      const idIndex = urlParts.indexOf('Add-Dealer-Car-Details-Copy') + 1;
      setId(urlParts[idIndex]);
      setDealerID(urlParts[idIndex + 1]);
    };

    extractIDsFromURL();
  }, []);

  useEffect(() => {
    if (id && dealerID) {
      fetchMakes();
      fetchCarModelDetails();
    }
  }, [id, dealerID]);

  const fetchMakes = async () => {
    try {
      const response = await axios.get(`https://api.icarbuyer.co/api/dealer/dealerMakes/${dealerID}`);
      setMakes(response.data.data);
    } catch (error) {
      console.error('Error fetching makes:', error);
    }
  };

  const fetchCarModelDetails = async () => {
    try {
      const response = await axios.get(`https://api.icarbuyer.co/api/dealer/getCarModelDetailsById/${id}`);
      setCarModelDetails(response.data.carModelDetails);
      setInitialCarModelDetails(response.data.carModelDetails);
    } catch (error) {
      console.error('Error fetching car model details:', error);
    }
  };
// console.log(id, dealerID)
  const handleMakeChange = (e) => {
    const make = e.target.value
    setSelectedMake(make)
    setCarModelDetails({ ...carModelDetails, make, vehicleType: '', model: '' })
    setCarModels([])

    const selectedMakeObj = makes.find((item) => item.make === make)
    setVehicleTypes(selectedMakeObj ? selectedMakeObj.vehicleTypes : [])
  }

  const handleVehicleTypeChange = (e) => {
    const vehicleType = e.target.value
    setCarModelDetails({ ...carModelDetails, vehicleType, model: '' })
    setCarModels([])

    if (selectedMake && vehicleType) {
      fetchCarModels(selectedMake, vehicleType)
    }
  }

  const fetchCarModels = async (make, vehicleType) => {
    try {
      const response = await axios.get(
        `https://api.icarbuyer.co/api/dealer/dealerCarModels/${dealerID}?make=${make}&vehicleType=${vehicleType}`,
      )
      setCarModels(response.data)
    } catch (error) {
      console.error('Error fetching car models:', error)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setCarModelDetails({
      ...carModelDetails,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (JSON.stringify(initialCarModelDetails) === JSON.stringify(carModelDetails)) {
      MySwal.fire({
        icon: 'error',
        // title: 'Error',
        text: 'No changes detected. Please make changes before saving.'
      });
      return;
    }
  
    try {
      // Clear the ID to ensure a new car model is created
      const newCarModelDetails = { ...carModelDetails, _id: undefined };
  
      const response = await axios.post(`https://api.icarbuyer.co/api/dealer/addCarModelDetails/${dealerID}`, {
        carModelDetails: [newCarModelDetails]
      });
  
      MySwal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message
      });
      setStep(5);
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'An error occurred while adding car model details.'
      });
    }
  };
  
  const [step, setStep] = useState(1)

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1)
  }

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1)
  }

  const steps = [
    { title: 'Basic Details' },
    { title: 'Protection Packages' },
    { title: 'Warranty, Insurance & Taxes' },
    { title: 'Extras' },
    // { title: 'Cash, Finance or Lease' },
    { title: 'Finish' },
  ]

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div>
                  <h4 className=" text-info m-1">LISTING NEW DEALER CAR</h4>
                  <h6 className="mx-2">Fill all the form to go to next step</h6>
                </div>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Home</a>
                    </li>

                    <li className="breadcrumb-item active text-info">Car Listing</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-2">
                <div className="card-body">
                  <Stepper
                    steps={steps}
                    activeStep={step - 1}
                    activeColor="#0DCAF0" // Change this color to match your active step color
                    completeColor="#0DCAF0" // Change this color to match your completed step color
                    defaultBarColor="#E0E0E0" // Change this color to match your step bar color
                    completeBarColor="#0DCAF0" // Change this color to match your completed step bar color
                    circleTop={0} // Adjust the vertical alignment of the circle icons
                    activeStepCircleSize={40} // Adjust the size of the active step circle
                  />

                  {step === 5 ? (
                    <div className="m-4 p-">
                      <div
                        className="progress m-3"
                        role="progressbar"
                        aria-label="Animated striped example"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated bg-info"
                          style={{ width: '100%' }}
                        ></div>
                      </div>
                      <h4></h4>
                      <div className="step">
                        <h1></h1>
                        <p>Step 5 of 5</p>
                      </div>
                      <h2 className="text-success text-center">
                        <strong>SUCCESS !</strong>
                      </h2>
                      <br />
                      {/* <div className="row justify-content-center mx-2">
                     <div className="col-3">
                       <img src="https://i.imgur.com/GwStPmg.png" style={{ height: '150px', width: '150px' }} className="fit-image" />
                     </div>
                   </div> */}
                      <br />
                      <div className="row justify-content-center">
                        <div className="col-8 text-center">
                          <h5 className="purple-text text-center">Car Added Successfully!</h5>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      {step === 1 && (
                        <div className="m-4">
                          <div
                            className="progress m-3"
                            role="progressbar"
                            aria-label="Animated striped example"
                            aria-valuenow="20"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated  bg-info"
                              style={{ width: '12%' }}
                            ></div>
                          </div>
                          <div className="step">
                            <h4>Basic Details</h4>
                            <p>Step 1 of 5</p>
                          </div>
                          <div className="col-12">
                            <Form.Group controlId="make">
                              <Form.Label>Make</Form.Label>
                              <Form.Control
                                as="select"
                                name="make"
                                value={carModelDetails.make}
                                onChange={handleMakeChange}
                                required
                              >
                                <option value="">Select Make</option>
                                {makes.map((make) => (
                                  <option key={make._id} value={make.make}>
                                    {make.make}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="vehicleType">
                              <Form.Label>Vehicle Type</Form.Label>
                              <Form.Control
                                as="select"
                                name="vehicleType"
                                value={carModelDetails.vehicleType}
                                onChange={handleVehicleTypeChange}
                                required
                              >
                                <option value="">Select Vehicle Type</option>
                                {vehicleTypes.map((type, index) => (
                                  <option key={index} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="model">
                              <Form.Label>Model Name</Form.Label>
                              <Form.Control
                                as="select"
                                name="model"
                                value={carModelDetails.model}
                                onChange={handleChange}
                                required
                              >
                                <option value="">Select Model Name</option>
                                {carModels.map((model, index) => (
                                  <option key={index} value={model}>
                                    {model}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="trim">
                              <Form.Label>Trim</Form.Label>
                              <Form.Control
                                type="text"
                                name="trim"
                                value={carModelDetails.trim}
                                onChange={handleChange}
                                required
                              />
                            </Form.Group>
                            <Form.Group controlId="year">
                              <Form.Label>Year</Form.Label>
                              <Form.Control
                                type="text"
                                name="year"
                                value={carModelDetails.year}
                                onChange={handleChange}
                                required
                              />
                            </Form.Group>
                            <Form.Group controlId="type">
                              <Form.Label>Vehicle Condition</Form.Label>
                              <div>
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="New"
                                  name="type"
                                  value="New"
                                  checked={carModelDetails.type === 'New'}
                                  onChange={handleChange}
                                />
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="PreOwned"
                                  name="type"
                                  value="PreOwned"
                                  checked={carModelDetails.type === 'PreOwned'}
                                  onChange={handleChange}
                                />
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="CertifiedPreOwned"
                                  name="type"
                                  value="CertifiedPreOwned"
                                  checked={carModelDetails.type === 'CertifiedPreOwned'}
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                            {(carModelDetails.type === 'PreOwned' ||
                              carModelDetails.type === 'CertifiedPreOwned') && (
                              <Form.Group controlId="mileage">
                                <Form.Label>Mileage</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="mileage"
                                  value={carModelDetails.mileage}
                                  onChange={handleChange}
                                  required
                                />
                              </Form.Group>
                            )}
                            <Form.Group controlId="basePrice">
                              <Form.Label>Base Price</Form.Label>
                              <Form.Control
                                type="text"
                                name="basePrice"
                                value={carModelDetails.basePrice}
                                placeholder="Enter Base Price"
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>

                          <button
                            type="button"
                            className="btn btn-outline-info m-2 p-2 align-right"
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        </div>
                      )}
                      {step === 2 && (
                        <div className=" m-4">
                          <div
                            className="progress m-3"
                            role="progressbar"
                            aria-label="Animated striped example"
                            aria-valuenow="27"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated  bg-info"
                              style={{ width: '29%' }}
                            ></div>
                          </div>
                          <div className="step">
                            <h4>Protection Packages</h4>
                            <p>Step 2 of 5</p>
                          </div>
                          <div className="container">
                            <div className="row"></div>
                          </div>
                          <div className="col-12">
                            <Form.Group controlId="rustProofingDetails">
                              <Form.Label>Rust Proofing Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="rustProofingDetails"
                                value={carModelDetails.rustProofingDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="paintProtectionDetails">
                              <Form.Label>Paint Protection Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="paintProtectionDetails"
                                value={carModelDetails.paintProtectionDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="fabricProtectionDetails">
                              <Form.Label>Fabric Protection Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="fabricProtectionDetails"
                                value={carModelDetails.fabricProtectionDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="windowTintingDetails">
                              <Form.Label>Window Tinting Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="windowTintingDetails"
                                value={carModelDetails.windowTintingDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="otherProtectionPackagesdetails">
                              <Form.Label>Other Protection Packages Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="otherProtectionPackagesdetails"
                                value={carModelDetails.otherProtectionPackagesdetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>

                          <div className="button-container">
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handlePrevious}
                            >
                              Previous
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handleNext}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      )}
                      {step === 3 && (
                        <div className="m-4">
                          <div
                            className="progress m-3"
                            role="progressbar"
                            aria-label="Animated striped example"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated  bg-info"
                              style={{ width: '51%' }}
                            ></div>
                          </div>
                          <div className="step">
                            <h4>Warranty, Insurance & Taxes</h4>
                            <p>Step 3 of 5</p>
                          </div>
                          <div className="row"></div>
                          <div className="col-12">
                            <Form.Group controlId="comprehensiveWarrantyDetails">
                              <Form.Label>Comprehensive Warranty Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="comprehensiveWarrantyDetails"
                                value={carModelDetails.comprehensiveWarrantyDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="powerTrainWarrantyDetails">
                              <Form.Label>PowerTrain Warranty Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="powerTrainWarrantyDetails"
                                value={carModelDetails.powerTrainWarrantyDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="totalInsuranceDetails">
                              <Form.Label>Total Insurance Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="totalInsuranceDetails"
                                value={carModelDetails.totalInsuranceDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="estimatedTotalTaxesAndFeesDetails">
                              <Form.Label>Estimated Total Taxes And Fees Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="estimatedTotalTaxesAndFeesDetails"
                                value={carModelDetails.estimatedTotalTaxesAndFeesDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="adminFeeDetails">
                              <Form.Label>Admin Fee Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="adminFeeDetails"
                                value={carModelDetails.adminFeeDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="airTaxdetails">
                              <Form.Label>Air Tax Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="airTaxdetails"
                                value={carModelDetails.airTaxdetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="gasolineDetails">
                              <Form.Label>Gasoline Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="gasolineDetails"
                                value={carModelDetails.gasolineDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="omvicFeedetails">
                              <Form.Label>OMVIC Fee Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="omvicFeedetails"
                                value={carModelDetails.omvicFeedetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="lienRegistrationFeeDetails">
                              <Form.Label>Lien Registration Fee Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="lienRegistrationFeeDetails"
                                value={carModelDetails.lienRegistrationFeeDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="licenseFeeDetails">
                              <Form.Label>License Fee Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="licenseFeeDetails"
                                value={carModelDetails.licenseFeeDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="otherDetails">
                              <Form.Label>Other Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="otherDetails"
                                value={carModelDetails.otherDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="payableOnDeliveryDetails">
                              <Form.Label>Payable On Delivery Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="payableOnDeliveryDetails"
                                value={carModelDetails.payableOnDeliveryDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="taxRate">
                              <Form.Label>Tax Rate</Form.Label>
                              <div>
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="GST"
                                  name="taxRate"
                                  value="GST"
                                  checked={carModelDetails.taxRate === 'GST'}
                                  onChange={handleChange}
                                />
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="HST"
                                  name="taxRate"
                                  value="HST"
                                  checked={carModelDetails.taxRate === 'HST'}
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group controlId="taxRatePercentage">
                              <Form.Label>Tax Rate Percentage</Form.Label>
                              <Form.Control
                                type="text"
                                name="taxRatePercentage"
                                value={carModelDetails.taxRatePercentage}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="note">
                              <Form.Label>Note</Form.Label>
                              <Form.Control
                                type="text"
                                name="note"
                                value={carModelDetails.note}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>

                          <div className="button-container">
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handlePrevious}
                            >
                              Previous
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handleNext}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      )}

                      {step === 4 && (
                        <div className="m-4">
                          <div
                            className="progress m-3"
                            role="progressbar"
                            aria-label="Animated striped example"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated  bg-info"
                              style={{ width: '72%' }}
                            ></div>
                          </div>
                          <div className="step">
                            <h4>Extras</h4>
                            <p>Step 4 of 5</p>
                          </div>
                          <div className="col-12">
                            <Form.Group controlId="tireAndWheelDetails">
                              <Form.Label>Tire And Wheel Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="tireAndWheelDetails"
                                value={carModelDetails.tireAndWheelDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="sideStepsdetails">
                              <Form.Label>Side Steps Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="sideStepsdetails"
                                value={carModelDetails.sideStepsdetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="roofRacksDetails">
                              <Form.Label>Roof Racks Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="roofRacksDetails"
                                value={carModelDetails.roofRacksDetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="trailerPackagesdetails">
                              <Form.Label>Trailer Packages Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="trailerPackagesdetails"
                                value={carModelDetails.trailerPackagesdetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group controlId="sprayLinerdetails">
                              <Form.Label>Spray Liner Details</Form.Label>
                              <Form.Control
                                type="text"
                                name="sprayLinerdetails"
                                value={carModelDetails.sprayLinerdetails}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>

                          <div className="button-container">
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handlePrevious}
                            >
                              Previous
                            </button>
                            {/* <button className="btn btn-outline-info m-2 p-2">Submit</button> */}
                            <button type="submit" className="btn btn-outline-info m-2 p-2">
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DealerCarAddCopy
