import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEye, FaSearch } from 'react-icons/fa';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  FormControl,
  Pagination,
  Spinner,
} from 'react-bootstrap';

const MySwal = withReactContent(Swal);

const SubscriptionList = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await fetch(`https://api.icarbuyer.co/api/all-subscriptions?keyword=${searchTerm}&page=${currentPage}&limit=10`);
        const data = await response.json();
        if (!response.ok) throw new Error(data.message || 'Failed to fetch subscriptions');
        setSubscriptions(data.data);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, [searchTerm, currentPage]);

  if (loading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const formatPaymentMethod = (method) => {
    if (!method) return "";
    return method
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()      // Convert to lowercase
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };
  return (
    <Container fluid className="my-4">
      <Row>
        <Col>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 text-info">Lead Subscriptions List</h4>
            <InputGroup className="mb-3" style={{ width: '300px' }}>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <Button variant="outline-secondary" id="button-addon2">
                <FaSearch />
              </Button>
            </InputGroup>
          </div>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Dealer</th>
                <th>Plan</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Amount Paid</th>
                <th>Payment Method</th>
                <th>Invoice ID</th>
                <th>Subscription ID</th>
                <th>Status</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((sub, index) => (
                <tr key={sub._id}>
                  <td>{index + 1 + (currentPage - 1) * 10}</td>
                  <td>{formatPaymentMethod(sub?.dealerId?.businessName)}</td>
                  
                  <td>{`${sub?.planId?.name} `}</td>
                  <td>{new Date(sub.startDate).toLocaleDateString()}</td>
                  <td>{new Date(sub.endDate).toLocaleDateString()}</td>

                  <td>${sub.amountPaid}</td>
                  <td>{formatPaymentMethod(sub.paymentMethod)}</td>
                
                  
                  <td>{sub.invoiceId}</td>
                  <td>{sub.subscriptionId}</td>
                  <td>{formatPaymentMethod(sub.paymentStatus)}</td>
                  {/* <td>
                    <Link to={`/subscriptions/view/${sub._id}`}>
                      <Button variant="info" size="sm"><FaEye /></Button>
                    </Link>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            {[...Array(totalPages).keys()].map(x => (
              <Pagination.Item key={x + 1} active={x + 1 === currentPage} onClick={() => handlePageClick(x + 1)}>
                {x + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
}

export default SubscriptionList;
